import * as ls from 'local-storage'
import { errorNetworkToast } from '../toast'

export const detectNetwork = (detect: () => void, name?: string) => {
  if (window.navigator.onLine) {
    if (name) ls.set(name, new Date())
    detect()
  } else if (name && ls.get(name)) {
    detect()
  } else {
    errorNetworkToast()
  }
}

/* eslint-disable max-len */
import React, { useState } from 'react'
import { Grid, Box, Theme, Button, IconButton, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate, useParams } from 'react-router-dom'
import HtmlReactParser from 'html-react-parser'
import Moment from 'react-moment'
import moment from 'moment'
import * as process from 'process'
import { Layout, Modal, TrackEvent } from '../../components'
import { size } from '../../configs'
import { useReduxSelector } from '../../hooks'
import { CloseIcon, DiscountIcon, LogoIcon } from '../../imgs'
import { detectNetwork, str } from '../../utils'
import { WaitingListModal } from '../register/waiting-list'
import { Properties } from '../../types'
import { GradeRoot } from '../review/grade'

export const ServicePage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { id: idParam } = useParams()

  const { REACT_APP_API_URL } = process.env

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { data: constData } = useReduxSelector((state) => state.constants)
  const { data: userData } = useReduxSelector((state) => state.auth)

  const { NOW = 0 } = constData || {}
  const { widget_key = '', widget, products = [] } = userData || {}

  const [idService, setIdService] = useState<number>(Number(idParam))
  const [waitingListModal, setWaitingListModal] = useState<boolean>(false)
  const [showGradeModal, setShowGradeModal] = useState<boolean>(false)
  const [qrPay, setQrPay] = useState<boolean>(false)

  const onClose = () => navigate({ pathname: '../', search: '?index=main' })

  const onRegister = () => {
    TrackEvent({
      eventCategory: 'Click',
      eventAction: 'procedure',
      eventLabel: products[idService].slug,
    })
    if (widget_key && widget) navigate(`../register?service=${idService}`)
    else onOpenWaitingListModal()
  }

  const onOpenWaitingListModal = () => setWaitingListModal(true)

  const onCloseWaitingListModal = () => setWaitingListModal(false)

  const onOpenGrade = () => {
    setShowGradeModal(true)
    TrackEvent({
      eventCategory: 'Click',
      eventAction: 'comment',
      eventLabel: products[idService].slug,
    })
  }

  const onCloseGrade = () => setShowGradeModal(false)

  return (
    <Layout>
      <WaitingListModal service={products[idService]} show={waitingListModal} onClose={onCloseWaitingListModal} />
      <GradeRoot
        idService={idService}
        onCloseGrade={onCloseGrade}
        service={products[idService]}
        showGradeModal={showGradeModal}
      />

      <Modal
        open={qrPay}
        onClose={() => setQrPay((prev) => !prev)}
        title={`Оплата услуги<br />${products.length > 0 && products[idService]?.name}`}
      >
        <Grid container justifyContent="center">
          <Grid item>
            {userData?.qr_pay_link ? (
              <Box width={{ xs: 200, md: 400, xl: 800 }}>
                <img src={`${REACT_APP_API_URL}/qr_pay_image/${userData.id}`} alt="" width="100%" />
              </Box>
            ) : (
              <Box width={{ xs: 200, md: 400, xl: 800 }} height={{ xs: 200, md: 400, xl: 800 }} bgcolor="#C6C6C6" />
            )}
          </Grid>
        </Grid>
        <Box textAlign="center" m={{ xs: '20px 0', md: '40px 0', xl: '80px 0' }} fontWeight={600}>
          Отсканируйте QR-код
        </Box>
        <Grid container justifyContent="center">
          <Grid item>
            <Button variant="contained" color="secondary" onClick={() => setQrPay((prev) => !prev)}>
              Закрыть
            </Button>
          </Grid>
        </Grid>
      </Modal>

      <Box position="relative" width="100%" height="100%" overflow="hidden">
        <Box
          position="absolute"
          top={!xl ? 30 * size.scale : md ? 30 / size.scale : 30}
          right={!xl ? 60 * size.scale : md ? 60 / size.scale : 60}
        >
          <IconButton color="secondary" onClick={onClose}>
            <CloseIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
          </IconButton>
        </Box>

        <Grid container wrap="nowrap" height="100%">
          <Grid item width={!xl ? 630 * size.scale : md ? 630 / size.scale : 630} height="100%">
            <Box
              width={!xl ? 630 * size.scale : md ? 630 / size.scale : 630}
              height="100%"
              bgcolor="#fff"
              className={classes.short}
            >
              <Box
                px={`${!xl ? 60 * size.scale : md ? 60 / size.scale : 60}px`}
                py={`${!xl ? 40 * size.scale : md ? 60 / size.scale : 40}px`}
              >
                <Box className={classes.title}>{str.normalize(products[idService]?.name)}</Box>
                {products[idService]?.properties && (
                  <Box className={classes.infoShort}>
                    {products[idService]?.properties.map((k: Properties, i) => {
                      return (
                        <Grid
                          key={`short-${i}`}
                          container
                          wrap="nowrap"
                          mb={`${!xl ? 30 * size.scale : md ? 30 / size.scale : 30}px`}
                          spacing={!xl ? 2 * size.scale : md ? 2 / size.scale : 2}
                        >
                          <Grid item>
                            <img src={k.icon.link_icon} alt="" width={62} height={62} />
                          </Grid>
                          <Grid item>
                            <Box>{str.normalize(k.name)}</Box>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Box>
                )}
                <Box className={classes.titlePrice}>Цена за услугу</Box>
                <Box mb={`${!xl ? 50 * size.scale : md ? 50 / size.scale : 50}px`}>
                  <Box className={classes.price}>
                    от&nbsp;{products[idService]?.price_start.toLocaleString('ru-RU')}&nbsp;до&nbsp;
                    {products[idService]?.price_end.toLocaleString('ru-RU')}&nbsp;₽
                  </Box>
                  {products[idService]?.discount && (
                    <>
                      {products[idService].discount?.start_at !== null &&
                        products[idService].discount?.end_at !== null &&
                        moment(NOW.valueOf()).isBefore(moment(products[idService].discount?.end_at.valueOf())) && (
                          <Grid
                            container
                            wrap="nowrap"
                            justifyContent="flex-start"
                            alignItems="center"
                            mt={`${!xl ? 20 * size.scale : md ? 20 / size.scale : 20}px`}
                          >
                            <Grid item>
                              <DiscountIcon />
                            </Grid>
                            <Grid item>
                              <Box
                                color="#E06AB1"
                                fontWeight={500}
                                mt={`-${!xl ? 16 * size.scale : md ? 16 / size.scale : 16}px`}
                              >
                                {products[idService].discount?.start_at !== null &&
                                  products[idService].discount?.end_at !== null && (
                                    <Box fontSize={`${!xl ? 25 * size.scale : md ? 25 / size.scale : 25}px`}>
                                      с&nbsp;<Moment format="DD.MM">{products[idService].discount?.start_at}</Moment>
                                      &nbsp;по&nbsp;
                                      <Moment format="DD.MM">{products[idService].discount?.end_at}</Moment>
                                    </Box>
                                  )}
                                {products[idService].discount?.price_start &&
                                  products[idService].discount?.price_end && (
                                    <Box
                                      fontSize={`${!xl ? 28 * size.scale : md ? 28 / size.scale : 28}px`}
                                      fontWeight={600}
                                    >
                                      от&nbsp;{products[idService].discount?.price_start.toLocaleString('ru-RU')}
                                      &nbsp;до&nbsp;
                                      {products[idService].discount?.price_end.toLocaleString('ru-RU')}&nbsp;₽
                                    </Box>
                                  )}
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                    </>
                  )}
                </Box>
                {userData?.qr_pay_link && (
                  <Box mb={`${!xl ? 20 * size.scale : md ? 20 / size.scale : 20}px`}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnPrimary}
                      onClick={() => setQrPay((prev) => !prev)}
                    >
                      Оплатить услугу
                    </Button>
                  </Box>
                )}
                <Box mb={`${!xl ? 20 * size.scale : md ? 20 / size.scale : 20}px`}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.btnSecondary}
                    onClick={() => detectNetwork(onRegister)}
                  >
                    Записаться на услугу
                  </Button>
                </Box>
                <Button variant="contained" color="primary" className={classes.btnPrimary} onClick={onOpenGrade}>
                  Оценить услугу
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item width={!xl ? 1290 * size.scale : md ? 1290 / size.scale : 1290} height="100%">
            <Grid
              container
              justifyContent="center"
              mt={`${!xl ? 50 * size.scale : md ? 50 / size.scale : 50}px`}
              mb={`${!xl ? 60 * size.scale : md ? 60 / size.scale : 60}px`}
            >
              <Grid item>
                <LogoIcon width={md ? 285 / size.scale : 285} height={md ? 113 / size.scale : 113} />
              </Grid>
            </Grid>
            <Box width="100%" height={!xl ? 775 * size.scale : md ? 980 / size.scale : 775} className={classes.full}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Box className={classes.infoFull}>
                    {products[idService]?.description_full && HtmlReactParser(products[idService]?.description_full)}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, font, palette }: Theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 45 * size.scale,
    color: palette.primary.dark,
    marginBottom: 50 * size.scale,
    [down(2000)]: {
      fontSize: 45,
      marginBottom: 50,
    },
    [down(1000)]: {
      fontSize: 45 / size.scale,
      marginBottom: 50 / size.scale,
    },
  },
  short: {
    overflowY: 'scroll',
  },
  infoShort: {
    width: '100%',
    fontWeight: 400,
    lineHeight: 1.2,
    fontSize: 26 * size.scale,
    fontFamily: font.secondary,
    color: palette.primary.dark,
    marginBottom: 76 * size.scale,
    '& img': {
      width: 62,
      height: 62,
      [down(1000)]: {
        width: 62 / size.scale,
        height: 62 / size.scale,
      },
    },
    [down(2000)]: {
      fontSize: 26,
      marginBottom: 76,
    },
    [down(1000)]: {
      fontSize: 26 / size.scale,
      marginBottom: 76 / size.scale,
    },
  },
  titlePrice: {
    fontWeight: 500,
    fontSize: 25 * size.scale,
    color: palette.primary.dark,
    marginBottom: 10 * size.scale,
    [down(2000)]: {
      fontSize: 25,
      marginBottom: 10,
    },
    [down(1000)]: {
      fontSize: 25 / size.scale,
      marginBottom: 10 / size.scale,
    },
  },
  price: {
    fontWeight: 700,
    fontSize: 40 * size.scale,
    color: palette.primary.dark,
    [down(2000)]: {
      fontSize: 40,
    },
    [down(1000)]: {
      fontSize: 40 / size.scale,
    },
  },
  btnSecondary: {
    fontSize: `${22 * size.scale}px!important`,
    borderColor: `${palette.primary.dark}!important`,
    [down(2000)]: {
      fontSize: `${22}px!important`,
    },
    [down(1000)]: {
      fontSize: `${22 / size.scale}px!important`,
    },
  },
  btnPrimary: {
    fontSize: `${22 * size.scale}px!important`,
    color: `${palette.primary.dark}!important`,
    borderColor: `${palette.primary.dark}!important`,
    [down(2000)]: {
      fontSize: `${22}px!important`,
    },
    [down(1000)]: {
      fontSize: `${22 / size.scale}px!important`,
    },
  },
  full: {
    overflowY: 'scroll',
  },
  infoFull: {
    width: 960 * size.scale,
    fontWeight: 400,
    lineHeight: 1.3,
    fontSize: 28 * size.scale,
    fontFamily: font.secondary,
    marginBottom: 60 * size.scale,
    color: `${palette.primary.main}!important`,
    '& ol': {
      paddingInlineStart: `${40 * size.scale}px!important`,
      [down(2000)]: {
        paddingInlineStart: `${40}px!important`,
      },
      [down(1000)]: {
        paddingInlineStart: `${40 / size.scale}px!important`,
      },
      '& li': {
        paddingLeft: `${20 * size.scale}px!important`,
        paddingBottom: `${30 * size.scale}px!important`,
        [down(2000)]: {
          paddingLeft: `${20}px!important`,
          paddingBottom: `${30}px!important`,
        },
        [down(1000)]: {
          paddingLeft: `${20 / size.scale}px!important`,
          paddingBottom: `${30 / size.scale}px!important`,
        },
      },
      '& li::marker': {
        fontWeight: 600,
        fontSize: `${28 * size.scale}px!important`,
        paddingRight: `${20 * size.scale}px!important`,
        background: `${palette.primary.main}!important`,
        [down(2000)]: {
          fontSize: `${28}px!important`,
          paddingRight: `${20}px!important`,
        },
        [down(1000)]: {
          fontSize: `${28 / size.scale}px!important`,
          paddingRight: `${20 / size.scale}px!important`,
        },
      },
    },
    '& img': {
      maxWidth: 960 * size.scale,
      [down(2000)]: {
        maxWidth: 960,
      },
      [down(1000)]: {
        maxWidth: 960 / size.scale,
      },
    },
    [down(2000)]: {
      width: 960,
      fontSize: 28,
      marginBottom: 60,
    },
    [down(1000)]: {
      width: 960 / size.scale,
      fontSize: 28 / size.scale,
      marginBottom: 60 / size.scale,
    },
  },
}))

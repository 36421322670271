import React, { useState } from 'react'
import { RecommendationModal } from './recommendation'
import { Product, ReviewStore } from '../../../../types'
import { LocationQRCodeModal } from './location-qr-code.modal'

interface IHighGrade {
  idService: number
  locationQRCode: boolean
  reviewStore: ReviewStore
  onOpenWaitingListModal: () => void
  showRecommendationHighGrade: boolean
  onOpenLocationQRCodeModal: () => void
  onCloseLocationQRCodeModal: () => void
  onCloseRecommendationHighGrade: () => void
}

export const HighGrade: React.FC<IHighGrade> = ({
  idService,
  reviewStore,
  locationQRCode,
  onOpenWaitingListModal = () => {},
  showRecommendationHighGrade = false,
  onOpenLocationQRCodeModal = () => {},
  onCloseLocationQRCodeModal = () => {},
  onCloseRecommendationHighGrade = () => {},
}): JSX.Element => {
  return (
    <>
      <RecommendationModal
        idService={idService}
        reviewStore={reviewStore}
        show={showRecommendationHighGrade}
        onClose={onCloseRecommendationHighGrade}
        onOpenLocationQRCodeModal={onOpenLocationQRCodeModal}
      />
      <LocationQRCodeModal
        idService={idService}
        show={locationQRCode}
        onClose={onCloseLocationQRCodeModal}
        onOpenWaitingListModal={onOpenWaitingListModal}
      />
    </>
  )
}

import { Box, Button, Theme, useMediaQuery } from '@mui/material'
import React from 'react'
import { str } from '../utils'
import { size } from '../configs'

export const UpdateApp = () => (
  <Box>
    <Box textAlign="center" mb="10px">
      Доступны новые обновления.
    </Box>
    <Box display="flex" justifyContent="center">
      <Button
        sx={{
          color: '#fff',
          fontWeight: 600,
          borderRadius: 0,
          border: '1px solid #fff',
          fontSize: { xs: 10, md: 13, xl: 16 },
          p: { xs: '3px 20px', sm: '5px 24px', md: '7px 28px' },
        }}
      >
        Обновить
      </Button>
    </Box>
  </Box>
)

export const checkUpdateApp = () => (
  <Box>
    <Box textAlign="center" mb="10px">
      Проверьте доступные обновления.
    </Box>
    <Box display="flex" justifyContent="center">
      <Button
        sx={{
          color: '#fff',
          fontWeight: 600,
          borderRadius: 0,
          border: '1px solid #fff',
          fontSize: { xs: 10, md: 13, xl: 16 },
          p: { xs: '3px 20px', sm: '5px 24px', md: '7px 28px' },
        }}
      >
        Проверить
      </Button>
    </Box>
  </Box>
)

export const SuccessApp = () => <Box textAlign="center">Обновления успешно установлено.</Box>
export const NoUpdateApp = () => <Box textAlign="center">Обновления не найдены.</Box>
export const ErrorNetwork = () => (
  <Box>Интернет-соединение не найдено. Проверьте подключение вашего устройства к сети.</Box>
)

export const ErrorForm = ({ alert }: { alert: string[] }) => (
  <Box>
    {alert.map((k: string, i: number) => (
      <Box key={`alert-message-${i}`}>{str.normalize(k, true)}</Box>
    ))}
  </Box>
)

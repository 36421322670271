import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as ls from 'local-storage'
import { getUser } from './auth.service'
import { IAuthState } from './auth.types'
import { TOKEN, User } from '../../types'

const initialState: IAuthState = {
  data: undefined,
  loading: true,
  error: undefined,
  auth: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state: IAuthState) {
      ls.remove(TOKEN.PRIVATE)
      state.data = undefined
      state.auth = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state: IAuthState) => {})
    builder.addCase(getUser.fulfilled, (state: IAuthState, action: PayloadAction<{ data: User }>) => {
      state.data = action.payload.data
      state.auth = true
      state.loading = false
    })
    builder.addCase(getUser.rejected, (state: IAuthState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.auth = false
      state.loading = false
    })
  },
})

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer

import './styles/app.scss'
import './styles/animation.scss'
import './styles/preloader.scss'
import 'react-toastify/dist/ReactToastify.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './configs/yup.config'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import Moment from 'react-moment'
import moment from 'moment'
import { store } from './store'
import 'moment/locale/ru'
import { App } from './app/app'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ErrorFallback } from './pages'
import { theme } from './theme/theme'
import { updateAppToast } from './toast'
import 'moment-timezone'

moment.locale('ru')
moment.defaultFormat = 'DD.MM.YYYY'
Moment.globalLocale = 'ru'
Moment.globalFormat = 'DD.MM.YYYY'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <App />
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
)

serviceWorkerRegistration.register({ onUpdate: (reg) => updateAppToast(reg) })

import React from 'react'
import { Box, Autocomplete, useMediaQuery, Theme } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import { KeyboardArrowDown } from '@mui/icons-material'
import { GenericTextInput } from './generic-text-input'
import { size } from '../../configs'
import { InputType, SalonProps } from '../../types'

type Props = {
  autoComplete?: boolean
  form: UseFormReturn<any>
  name: string
  type?: InputType
  label?: string
  placeholder?: string
  disabled?: boolean
  processed?: boolean
  required?: boolean
  freeSolo?: boolean
  noOptionsText?: string
  defaultOptions?: any[]
  variant?: 'standard' | 'filled' | 'outlined'
  onSelect?: (value: SalonProps) => void
}

export const AutocompleteInput: React.FC<Props> = (props): JSX.Element => {
  const {
    form,
    name,
    label,
    type = 'text',
    disabled = false,
    processed = false,
    freeSolo = false,
    required = true,
    autoComplete = true,
    noOptionsText = 'Нет доступных вариантов',
    defaultOptions = [],
    variant,
    onSelect,
  } = props

  const { register, control, formState } = form

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, value, ...field } }) => (
        <Autocomplete
          {...field}
          value={value}
          freeSolo={freeSolo}
          options={defaultOptions}
          autoComplete={autoComplete}
          id={`autocomplete-${name}`}
          noOptionsText={noOptionsText}
          disabled={processed || disabled}
          getOptionLabel={(option: any) => `${option.name}, ${option.full_address}`}
          onChange={(_: any, newValue: any) => {
            if (onSelect) onSelect(newValue)
            onChange(`${newValue.name}, ${newValue.full_address}`)
          }}
          renderInput={(params) => (
            <GenericTextInput
              {...params}
              type={type}
              variant={variant}
              label={label ?? ''}
              helperText={formState.errors[name]?.message}
              register={register(name, { required, disabled })}
              {...{ ...params, InputLabelProps: { ...params.InputLabelProps } }}
            />
          )}
          popupIcon={<KeyboardArrowDown style={{ fontSize: !xl ? 44 * size.scale : md ? 44 / size.scale : 44 }} />}
          renderOption={(propsOption: any, option: SalonProps) => (
            <Box {...propsOption} fontWeight={400}>
              <Box>
                <Box fontSize={`${25 / size.scale}px`}>{option.name}</Box>
                <Box fontSize={`${22 / size.scale}px`} color="#5E5E5E">
                  {option.full_address}
                </Box>
              </Box>
            </Box>
          )}
        />
      )}
    />
  )
}

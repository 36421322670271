import React from 'react'
import { Grid, Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { size } from '../../configs'
import { Answer } from '../../types'

interface IQuestion {
  title: string
  answers: Answer[]
  variant: number
  onSelect: (id: number) => void
}

export const Question: React.FC<IQuestion> = ({
  title,
  answers = [],
  variant = null,
  onSelect = () => {},
}): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.content}>
      <Grid container justifyContent="center">
        <Grid item>
          <Box className={classes.title}>{title}</Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Box className={classes.titleText}>Выберите один вариант ответа:</Box>
        </Grid>
      </Grid>
      <Box className={clsx({ [classes.scroll]: answers.length > 3 })}>
        <Grid
          container
          wrap="nowrap"
          ml={answers.length > 3 ? `${40 / size.scale}px` : 0}
          justifyContent={answers.length > 3 ? 'flex-start' : 'space-evenly'}
        >
          {answers.map((k: Answer, i: number) => {
            return (
              <Grid item>
                <Box
                  className={clsx(classes.card, { [classes.selectCard]: variant === i })}
                  onClick={() => onSelect(i)}
                >
                  <img src={k.link_preview} alt="" className={classes.img} />
                  <Box className={classes.itemTitle}>{k.name}</Box>
                  <Box className={classes.itemText}>{k.description}</Box>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, font, palette }: Theme) => ({
  content: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  scroll: {
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    '-webkit-overflow-scrolling': 'touch',
  },
  title: {
    fontWeight: 800,
    textAlign: 'center',
    fontSize: 38 * size.scale,
    textTransform: 'uppercase',
    color: palette.primary.dark,
    marginBottom: 20 * size.scale,
    maxWidth: 800 * size.scale,
    [down(2000)]: {
      fontSize: 38,
      marginBottom: 20,
      maxWidth: 800,
    },
    [down(1000)]: {
      fontSize: 38 / size.scale,
      marginBottom: 20 / size.scale,
      maxWidth: 800 / size.scale,
    },
  },
  titleText: {
    fontWeight: 500,
    textAlign: 'center',
    fontSize: 28 * size.scale,
    color: palette.primary.dark,
    marginBottom: 30 * size.scale,
    maxWidth: 800 * size.scale,
    fontFamily: font.secondary,
    [down(2000)]: {
      fontSize: 28,
      marginBottom: 30,
      maxWidth: 800,
    },
    [down(1000)]: {
      fontSize: 28 / size.scale,
      marginBottom: 60 / size.scale,
      maxWidth: 800 / size.scale,
    },
  },
  card: {
    width: 420 * size.scale,
    height: 620 * size.scale,
    [down(2000)]: {
      width: 420,
      height: 550,
    },
    [down(1000)]: {
      width: 420 / size.scale,
      height: 620 / size.scale,
    },
  },
  img: {
    borderRadius: '50%',
    width: 220 * size.scale,
    height: 220 * size.scale,
    padding: `${30 * size.scale}px ${100 * size.scale}px ${30 * size.scale}px`,
    [down(2000)]: {
      width: 220,
      height: 220,
      padding: `${30}px ${100}px ${30}px`,
    },
    [down(1000)]: {
      width: 220 / size.scale,
      height: 220 / size.scale,
      padding: `${30 / size.scale}px ${100 / size.scale}px ${40 / size.scale}px`,
    },
  },
  itemTitle: {
    fontWeight: 800,
    fontSize: 24 * size.scale,
    textTransform: 'uppercase',
    color: palette.primary.dark,
    marginBottom: 15 * size.scale,
    padding: `0 ${20 * size.scale}px`,
    [down(2000)]: {
      fontSize: 24,
      marginBottom: 15,
      padding: `0 ${20}px`,
    },
    [down(1000)]: {
      fontSize: 25 / size.scale,
      marginBottom: 15 / size.scale,
      padding: `0 ${20 / size.scale}px`,
    },
  },
  itemText: {
    fontWeight: 400,
    lineHeight: 1.3,
    fontSize: 23 * size.scale,
    fontFamily: font.secondary,
    color: palette.primary.dark,
    padding: `0 ${20 * size.scale}px`,
    [down(2000)]: {
      fontSize: 23,
      padding: `0 ${20}px`,
    },
    [down(1000)]: {
      fontSize: 24 / size.scale,
      padding: `0 ${20 / size.scale}px`,
    },
  },
  selectCard: {
    backgroundColor: '#F4F4F4',
  },
}))

import React, { useEffect, useCallback, useState } from 'react'
import { Box, CircularProgress, Grid, Theme, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { SigninForm } from './signin.form'
import { Layout } from '../../components'
import { size } from '../../configs'
import { LogoIcon } from '../../imgs'
import { salons } from '../../api'
import { SalonProps } from '../../types'

export const SigninPage: React.FC = (): JSX.Element => {
  const { REACT_APP_VERSION: VERSION } = process.env
  const classes = useStyles()

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const [salonsList, setSalonsList] = useState<SalonProps[]>([])

  const getSalons = useCallback(() => {
    salons()
      .then((res) => {
        const { data = [], status = 0 } = res
        if (status === 1) {
          setSalonsList(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  useEffect(() => getSalons(), [])

  return (
    <Layout>
      <Grid container justifyContent="center" className={classes.content}>
        <Grid item>
          <Box height={!xl ? 820 * size.scale : md ? 820 / size.scale : 820}>
            <Box
              display="flex"
              justifyContent="center"
              mt={`${!xl ? 80 * size.scale : md ? 80 / size.scale : 80}px`}
              mb={`${!xl ? 140 * size.scale : md ? 140 / size.scale : 140}px`}
            >
              <LogoIcon width={md ? 343 / size.scale : 343} height={md ? 135 / size.scale : 135} />
            </Box>
            {salonsList.length > 0 ? (
              <>
                <Box
                  className={classes.title}
                  fontSize={!xl ? 45 * size.scale : md ? 45 / size.scale : 45}
                  mb={`${!xl ? 50 * size.scale : md ? 50 / size.scale : 50}px`}
                >
                  Авторизация
                </Box>
                <SigninForm salonsList={salonsList} />
                <Box
                  className={classes.version}
                  fontSize={!xl ? 18 * size.scale : md ? 18 / size.scale : 18}
                  mb={`${!xl ? 20 * size.scale : md ? 20 / size.scale : 20}px`}
                >
                  версия {VERSION}
                </Box>
              </>
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                height={!xl ? 500 * size.scale : md ? 500 / size.scale : 500}
              >
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  content: {
    width: '100%',
    height: '100%',
    minHeight: 820 * size.scale,
    backgroundColor: palette.primary.dark,
    [down(2000)]: {
      minHeight: 820,
    },
    [down(1000)]: {
      minHeight: 820 / size.scale,
    },
  },
  title: {
    fontWeight: 800,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: palette.primary.main,
  },
  version: {
    fontWeight: 400,
    userSelect: 'none',
    textAlign: 'center',
    color: palette.primary.dark,
  },
}))

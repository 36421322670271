export enum KEYMOVE {
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  SHIFT = 'Shift',
  CTRL = 'Control',
  BACKSPACE = 'Backspace',
  TAP = 'Pressing',
  ESC = 'Escape',
}

import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { AppBar, Grid, Box, Button, Theme, IconButton, useMediaQuery } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Slider from 'react-slick'
import * as process from 'process'
import { DetectFullScreen, DetectLogout, HotReload, Layout, Menu, Modal } from '../../components'
import { size } from '../../configs'
import { ArrowBackIcon, LogoIcon } from '../../imgs'
import { useReduxSelector, useWindowDimension } from '../../hooks'
import { detectNetwork } from '../../utils'

const NextArrow = (props: any) => {
  const { className, style, onClick } = props

  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  return (
    <Box className={className} style={{ ...style }}>
      <IconButton color="secondary" onClick={onClick}>
        <Box sx={{ transform: 'rotate(180deg)', mt: -1, opacity: 0.5 }}>
          <ArrowBackIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
        </Box>
      </IconButton>
    </Box>
  )
}

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props

  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  return (
    <Box className={className} style={{ ...style }}>
      <IconButton color="secondary" onClick={onClick}>
        <Box sx={{ opacity: 0.5 }}>
          <ArrowBackIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
        </Box>
      </IconButton>
    </Box>
  )
}

export const MainPage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [searchParam, setSearchParam] = useSearchParams()

  const slickRef = useRef<any>()
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const { height } = useWindowDimension()

  const { REACT_APP_DEBUG: DEBUG, REACT_APP_API_URL } = process.env

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { data: userData } = useReduxSelector((state) => state.auth)

  const { products = [] } = userData || {}

  const [activeSlide, setActiveSlide] = useState<number>(0)
  const [autoPlay, setAutoPlay] = useState<boolean>(true)

  const [qrPay, setQrPay] = useState<boolean>(false)

  const settings = {
    speed: 300,
    dots: false,
    easing: 'cubic',
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: (index: number) => {
      setActiveSlide(index)
      if (products.length === index + 1) {
        const video = document.querySelectorAll<any>(`#service-${index}`)
        video[1].play()
      } else if (products.length > index - 1) {
        const video = document.getElementById(`service-${index}`) as HTMLVideoElement
        video.play()
      }
    },
    beforeChange: (current: number, _: number) => {
      if (products.length - 1 === current) {
        const video = document.querySelectorAll<any>(`#service-${current}`)
        video[1].pause()
        video[1].currentTime = 0
      } else if (products.length - 1 > current) {
        const video = document.getElementById(`service-${current}`) as HTMLVideoElement
        video.pause()
        video.currentTime = 0
      }
    },
  }

  const onEndedVideo = () => {
    if (products.length > activeSlide + 1) {
      slickRef.current.slickGoTo(activeSlide + 1)
      setActiveSlide(activeSlide + 1)
    } else {
      slickRef.current.slickGoTo(0)
      setActiveSlide(0)
    }
  }

  const onOpenService = () => {
    detectNetwork(() => navigate(`../service/${activeSlide}`), `__more_service=${activeSlide}`)
  }

  const onGoToDots = (index: number) => slickRef.current.slickGoTo(index)

  useEffect(() => {
    searchParam.set('index', 'main')
    setSearchParam(searchParam)
    if (videoRef && autoPlay && products.length > 0) {
      const video = document.getElementById('service-0') as HTMLVideoElement
      video.play().then(() => setAutoPlay(false))
    }
  }, [])

  return (
    <Layout>
      <DetectLogout touchElement="logout" />
      {!md && <DetectFullScreen touchElement="fullscreen" />}

      <Box className={classes.page}>
        <Modal
          open={qrPay}
          onClose={() => setQrPay((prev) => !prev)}
          title={`Оплата услуги<br />${products.length > 0 && products[activeSlide]?.name}`}
        >
          <Grid container justifyContent="center">
            <Grid item>
              {userData?.qr_pay_link ? (
                <Box width={{ xs: 200, md: 400, xl: 800 }}>
                  <img src={`${REACT_APP_API_URL}/qr_pay_image/${userData.id}`} alt="" width="100%" />
                </Box>
              ) : (
                <Box width={{ xs: 200, md: 400, xl: 800 }} height={{ xs: 200, md: 400, xl: 800 }} bgcolor="#C6C6C6" />
              )}
            </Grid>
          </Grid>
          <Box textAlign="center" m={{ xs: '20px 0', md: '40px 0', xl: '80px 0' }} fontWeight={600}>
            Отсканируйте QR-код
          </Box>
          <Grid container justifyContent="center">
            <Grid item>
              <Button variant="contained" color="secondary" onClick={() => setQrPay((prev) => !prev)}>
                Закрыть
              </Button>
            </Grid>
          </Grid>
        </Modal>

        <AppBar position="absolute">
          {!md && (
            <Box
              top={0}
              left={0}
              id="fullscreen"
              position="absolute"
              width={!xl ? 250 * size.scale : md ? 250 / size.scale : 250}
              height={!xl ? 250 * size.scale : md ? 250 / size.scale : 250}
            />
          )}

          <HotReload />

          <Box display="flex" justifyContent="center" mt={`${md ? 80 / size.scale : 80}px`}>
            <Box id="logout">
              <LogoIcon
                width={!xl ? 343 * size.scale : md ? 343 / size.scale : 343}
                height={!xl ? 135 * size.scale : md ? 135 / size.scale : 135}
              />
            </Box>
          </Box>
        </AppBar>

        <Box className={classes.slider}>
          {products && (
            <Slider ref={slickRef} {...settings}>
              {products.map((k, i) => {
                return (
                  <Box key={`slide-${i}`} className={classes.slide}>
                    <Box position="relative" width="100%" height="100%">
                      <video
                        muted
                        playsInline
                        src={k.link_video}
                        id={`service-${i}`}
                        onEnded={onEndedVideo}
                        width={md ? 'auto' : '100%'}
                        loop={products.length === 1}
                        ref={i === 0 ? videoRef : null}
                        crossOrigin={DEBUG ? undefined : 'anonymous'}
                      />
                      <Box className={classes.opacitySlide} height={height}>
                        <Box className={userData?.qr_pay_link ? classes.serviceAll : classes.service}>{k.name}</Box>
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            </Slider>
          )}
        </Box>

        <Box className={userData?.qr_pay_link ? classes.bottomAll : classes.bottom}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item minWidth={268 / size.scale}>
              {products.length > 0 &&
                products.map((_, i) => {
                  return (
                    <Box
                      key={`dots-${i}`}
                      onClick={() => onGoToDots(i)}
                      mr={i === products.length - 1 ? 0 : '10px'}
                      width={{ xs: 44 / size.scale, md: 44, xl: 44 * size.scale }}
                      height={{ xs: 44 / size.scale, md: 44, xl: 44 * size.scale }}
                      className={activeSlide === i ? 'dots' : 'dots-not-active'}
                    >
                      <Box className={classes.dots} />
                    </Box>
                  )
                })}
            </Grid>
            <Grid item>
              <Grid container direction="column" spacing={{ xs: 2, xl: 4 }}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      bgcolor: userData?.qr_pay_link ? '#FFFFFF' : 'transparent',
                      color: userData?.qr_pay_link ? '#000000' : '#FFFFFF',
                    }}
                    onClick={onOpenService}
                  >
                    Подробнее об услуге
                  </Button>
                </Grid>
                {userData?.qr_pay_link && (
                  <Grid item>
                    <Box>
                      <Button variant="contained" color="primary" onClick={() => setQrPay((prev) => !prev)}>
                        Оплатить услугу
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Menu />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  page: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  slider: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: palette.primary.dark,
    '& .slick-next:before, .slick-prev:before': {
      content: 'none',
      width: `${40 * 2}px!important`,
      height: `${40 * 2}px!important`,
      [down(1000)]: {
        width: `${40}px!important`,
        height: `${40}px!important`,
      },
    },
    '& .slick-next': {
      right: 25 * size.scale,
      top: '45%',
      width: `${40 * size.scale}px!important`,
      height: `${40 * size.scale}px!important`,
      [down(1000)]: {
        right: 25,
        top: '40%',
        width: `${40}px!important`,
        height: `${40}px!important`,
      },
    },
    '& .slick-prev': {
      left: 18 * size.scale,
      top: '45%',
      zIndex: 1000,
      width: `${40 * size.scale}px!important`,
      height: `${40 * size.scale}px!important`,
      [down(1000)]: {
        left: 10,
        top: '40%',
        width: `${40}px!important`,
        height: `${40}px!important`,
      },
    },
  },
  slide: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  opacitySlide: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    position: 'absolute',
    maxHeight: 1080 * size.scale,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    [down(2000)]: {
      maxHeight: 1080,
    },
    [down(1000)]: {
      maxHeight: 1200 / size.scale,
    },
  },
  content: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  bottom: {
    width: `calc(${1920 * size.scale}px - ${70 * size.scale * 2}px)`,
    margin: `-${160 * size.scale}px ${70 * size.scale}px ${60 * size.scale}px`,
    [down(2000)]: {
      width: `calc(${1920}px - ${70 * 2}px)`,
      margin: `-${160}px ${70}px ${60}px`,
    },
    [down(1000)]: {
      width: `calc(${1920 / size.scale}px - ${(70 / size.scale) * 2}px)`,
      margin: `-${182 / size.scale}px ${70 / size.scale}px ${60 / size.scale}px`,
    },
  },
  bottomAll: {
    width: `calc(${1920 * size.scale}px - ${70 * size.scale * 2}px)`,
    margin: `-${160 * size.scale}px ${70 * size.scale}px ${60 * size.scale}px`,
    [down(2000)]: {
      width: `calc(${1920}px - ${70 * 2}px)`,
      margin: `-${220}px ${70}px ${60}px`,
    },
    [down(1000)]: {
      width: `calc(${1920 / size.scale}px - ${(70 / size.scale) * 2}px)`,
      margin: `-${245 / size.scale}px ${70 / size.scale}px ${60 / size.scale}px`,
    },
  },
  serviceAll: {
    width: '100%',
    fontWeight: 600,
    textAlign: 'center',
    position: 'absolute',
    bottom: 250 * size.scale,
    fontSize: 50 * size.scale,
    textTransform: 'uppercase',
    color: palette.primary.main,
    [down(2000)]: {
      bottom: 250,
      fontSize: 50,
    },
    [down(1000)]: {
      bottom: 280 / size.scale,
      fontSize: 50 / size.scale,
    },
  },
  service: {
    width: '100%',
    fontWeight: 600,
    textAlign: 'center',
    position: 'absolute',
    bottom: 230 * size.scale,
    fontSize: 50 * size.scale,
    textTransform: 'uppercase',
    color: palette.primary.main,
    [down(2000)]: {
      bottom: 230,
      fontSize: 50,
    },
    [down(1000)]: {
      bottom: 230 / size.scale,
      fontSize: 50 / size.scale,
    },
  },
  boxDots: {
    width: 44 * size.scale,
    height: 44 * size.scale,
    float: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid transparent',
    [down(2000)]: {
      width: 44,
      height: 44,
    },
    [down(1000)]: {
      width: 44 / size.scale,
      height: 44 / size.scale,
    },
  },
  dots: {
    float: 'left',
    width: 24 * size.scale,
    height: 24 * size.scale,
    backgroundColor: palette.primary.main,
    border: '2px solid transparent',
    [down(2000)]: {
      width: 24,
      height: 24,
    },
    [down(1000)]: {
      width: 24 / size.scale,
      height: 24 / size.scale,
    },
  },
}))

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Theme, useMediaQuery } from '@mui/material'
import { TextInput, SubmitButton } from '../../../components'
import { FormProps, SubmitGradeFormProps } from '../../../types'
import { useReduxActions, useReduxSelector } from '../../../hooks'
import { size } from '../../../configs'
import { Grade } from './grade'

interface IGradeForm {
  className: any
  onOpenLowGrade: () => void
  onOpenHighGrade: () => void
  onCloseGradeModal: () => void
  onSubmitGradeForm: (data: SubmitGradeFormProps) => void
}
type GradeProps = { specialist: string }

const schema = yup.object().shape({ specialist: yup.string().required() })

export const GradeForm: React.FC<IGradeForm> = ({
  className,
  onOpenLowGrade = () => {},
  onOpenHighGrade = () => {},
  onCloseGradeModal = () => {},
  onSubmitGradeForm = () => {},
}): JSX.Element => {
  const { setAlert } = useReduxActions()

  const { data: userData } = useReduxSelector((state) => state.auth)

  const { two_gis_card = '', yandex_card = '' } = userData || {}

  const hookForm = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const [formProps, setFormProps] = useState<FormProps<GradeProps>>({
    data: { specialist: '' },
    processed: false,
  })

  const [selectGrade, setSelectGrade] = useState<number>(0)

  const onSubmitForm = async ({ specialist }: GradeProps) => {
    setFormProps({ ...formProps, processed: true })

    if (selectGrade <= 7) {
      setFormProps({ ...formProps, processed: false })
      onSubmitGradeForm({ specialist, rating: selectGrade })
      onCloseGradeModal()
      setTimeout(() => onOpenLowGrade(), 400)
    } else {
      setFormProps({ ...formProps, processed: false })
      onSubmitGradeForm({ specialist, rating: selectGrade })
      onCloseGradeModal()
      setTimeout(() => {
        // setAlert({
        //   alert: {
        //     title: 'Мы рады, что вам у нас понравилось!',
        //     message: { info: [`Порекомендуйте нас друзьям и получите<br />1000 рублей на следующий визит!`] },
        //     actionButtonText: 'Рекомендовать',
        //     onClose() {
        //       setTimeout(() => onOpenHighGrade(), 400)
        //     },
        //   },
        // })
        setAlert({
          alert: {
            title: 'Мы рады, что вам у нас понравилось!',
            message: { info: [`Благодарим за положительный отзыв!`] },
            actionButtonText: 'Продолжить',
            onClose() {
              if (two_gis_card || yandex_card) setTimeout(() => onOpenHighGrade(), 400)
            },
          },
        })
      }, 400)
    }
  }

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      <Grid
        container
        justifyContent="center"
        height={!xl ? 126 * size.scale : md ? 126 / size.scale : 126}
        mb={`${!xl ? 60 * size.scale : md ? 60 / size.scale : 60}px`}
      >
        <Grid item>
          <TextInput
            variant="filled"
            name="specialist"
            label="Укажите имя специалиста"
            form={hookForm}
            disabled={formProps.processed}
          />
        </Grid>
      </Grid>
      <Box className={className.titleGrade}>Выберите оценку</Box>
      <Grade className={className} select={selectGrade} onSelectGrade={(id) => setSelectGrade(id)} />
      <Box
        mx="auto"
        width="100%"
        maxWidth={!xl ? 640 * size.scale : md ? 640 / size.scale : 640}
        mt={`${!xl ? 45 * size.scale : md ? 45 / size.scale : 45}px`}
      >
        <SubmitButton
          fullWidth
          title="Продолжить"
          processed={formProps.processed}
          disabled={formProps.processed || selectGrade === 0}
        />
      </Box>
    </form>
  )
}

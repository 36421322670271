import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Theme, useMediaQuery } from '@mui/material'
import { Check } from '@mui/icons-material'
import { TextInput, CheckboxInput, PhoneInput, SubmitButton, formLocale, EmailInput } from '../../../../../components'
import { FormProps, ReviewStore } from '../../../../../types'
import { useReduxActions, useReduxSelector } from '../../../../../hooks'
import { errorFormToast } from '../../../../../toast'
import { size } from '../../../../../configs'
import { reviewStores } from '../../../../../api'

type RecommendationProps = { name: string; phone: string; email: string; rules: boolean; sms_email_subscribe: boolean }

interface IRecommendationForm {
  className: any
  onClose: () => void
  reviewStore: ReviewStore
  onOpenLocationQRCodeModal: () => void
}

const schema = yup.object().shape({
  name: yup.string().required(),
  phone: yup
    .string()
    .required()
    .transform((value) => value.replace(/\s|\(|\)|\-|\+7/g, ''))
    .matches(/^\d{10}$/, 'Указан некорректный номер телефона'),
  email: yup.string().required(),
  rules: yup.boolean().oneOf([true], 'Необходимо согласиться с условиями').default(false),
})

export const RecommendationForm: React.FC<IRecommendationForm> = ({
  className,
  reviewStore,
  onClose = () => {},
  onOpenLocationQRCodeModal = () => {},
}): JSX.Element => {
  const { setAlert } = useReduxActions()

  const { serverError, rulesCheckbox, subscribeSmsEmailCheckbox } = formLocale

  const { data: userData } = useReduxSelector((state) => state.auth)

  const { yandex_card = '', two_gis_card = '' } = userData || {}

  const hookForm = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const [formProps, setFormProps] = useState<FormProps<RecommendationProps>>({
    data: { name: '', phone: '', email: '', rules: false, sms_email_subscribe: false },
    processed: false,
  })

  const onSubmitForm = async (data: RecommendationProps) => {
    const { name, phone, email, rules, sms_email_subscribe } = data

    setFormProps({ ...formProps, processed: true })

    return await reviewStores({
      ...reviewStore,
      name,
      phone,
      email,
      rules,
      email_subscribe: sms_email_subscribe,
      sms_subscribe: sms_email_subscribe,
    })
      .then((res) => {
        setFormProps({ ...formProps, processed: false })

        if (res.status === 1) {
          onClose()

          setTimeout(() => {
            setAlert({
              alert: {
                title: `Уникальный код отправлен на e-mail, который вы указали.`,
                message: {
                  info: [
                    'Если по вашему совету кто-то станет клиентом нашего салона и предъявит уникальный код, ' +
                      'вы оба получите 1000 рублей на одну из услуг.',
                  ],
                },
                actionButtonText: 'Продолжить',
                onClose() {
                  if (yandex_card || two_gis_card) {
                    setTimeout(() => onOpenLocationQRCodeModal(), 400)
                  }
                },
              },
            })
          }, 400)
        }
      })
      .catch((err) => {
        setFormProps({ ...formProps, processed: false })

        if (err.response.data.status === -1 || err.response.data.status === 0) {
          errorFormToast(err.response.data.message)
        } else {
          errorFormToast(serverError)
        }
      })
  }

  const check = {
    icon: <span className={className.icon} />,
    checked: (
      <span className={className.iconCheck}>
        <Check className={className.check} />
      </span>
    ),
  }

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      <Box width="100%" height={!xl ? 126 * size.scale : md ? 126 / size.scale : 126}>
        <TextInput variant="filled" name="name" label="Имя" form={hookForm} disabled={formProps.processed} />
      </Box>
      <Box width="100%" height={!xl ? 126 * size.scale : md ? 126 / size.scale : 126}>
        <PhoneInput variant="filled" name="phone" label="Телефон" form={hookForm} disabled={formProps.processed} />
      </Box>
      <Box width="100%" height={!xl ? 126 * size.scale : md ? 126 / size.scale : 126}>
        <EmailInput variant="filled" name="email" form={hookForm} disabled={formProps.processed} />
      </Box>
      <Box
        width={!xl ? 640 * size.scale : md ? 640 / size.scale : 640}
        height={!xl ? 80 * size.scale : md ? 80 / size.scale : 80}
      >
        <CheckboxInput
          name="rules"
          color="secondary"
          label={rulesCheckbox}
          icon={check.icon}
          checked={check.checked}
          required
          form={hookForm}
        />
      </Box>
      <Box width={!xl ? 640 * size.scale : md ? 640 / size.scale : 604}>
        <CheckboxInput
          form={hookForm}
          icon={check.icon}
          color="secondary"
          checked={check.checked}
          name="sms_email_subscribe"
          label={subscribeSmsEmailCheckbox}
        />
      </Box>
      <Box
        mx="auto"
        width="100%"
        maxWidth={!xl ? 640 * size.scale : md ? 640 / size.scale : 640}
        mt={`${!xl ? 45 * size.scale : md ? 45 / size.scale : 45}px`}
      >
        <SubmitButton fullWidth disabled={formProps.processed} processed={formProps.processed} title="Продолжить" />
      </Box>
    </form>
  )
}

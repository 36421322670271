import React, { useEffect, useState } from 'react'
import { KEYMOVE } from '../../types'
import { useTouchDetect } from '../../hooks'

interface IDetectLogout {
  touchElement: string
}

export const DetectFullScreen: React.FC<IDetectLogout> = ({ touchElement }) => {
  const { touch, watch: touchWatch } = useTouchDetect(touchElement)

  const [fullScreenCountTouch, setFullScreenCountTouch] = useState<number>(0)

  useEffect(() => {
    if (touch && touch === KEYMOVE.TAP) setFullScreenCountTouch(fullScreenCountTouch + 1)
  }, [touchWatch])

  useEffect(() => {
    if (fullScreenCountTouch > 2) {
      if (document.fullscreenElement) {
        document.exitFullscreen()
        setFullScreenCountTouch(0)
      } else {
        document.documentElement.requestFullscreen()
        setFullScreenCountTouch(0)
      }
    }
  }, [fullScreenCountTouch])

  return <></>
}

import React, { useState } from 'react'
import { Box, IconButton, Theme, useMediaQuery } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { makeStyles } from '@mui/styles'
import { Layout, TrackEvent } from '../../components'
import { size } from '../../configs'
import { CloseIcon } from '../../imgs'
import { WaitingListModal } from './waiting-list'
import { useReduxSelector } from '../../hooks'
import { SonlineWidget, YClientsWidget } from './widgets'

export const RegisterPage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { search } = useLocation()
  const parsed = queryString.parse(search)

  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  const { data: userData } = useReduxSelector((state) => state.auth)

  const { products = [], widget_key = '', widget } = userData || {}

  const [waitingListModal, setWaitingListModal] = useState<boolean>(false)
  const onClose = () => navigate(`../service/${parsed.service}`)

  const onOpenWaitingListModal = () => {
    setWaitingListModal(true)
    TrackEvent({
      eventCategory: 'Click',
      eventAction: 'waiting_list',
      eventLabel: products[Number(parsed.service)].slug,
    })
  }

  const onCloseWaitingListModal = () => setWaitingListModal(false)

  return (
    <Layout>
      <WaitingListModal
        show={waitingListModal}
        onClose={onCloseWaitingListModal}
        service={products[Number(parsed.service)]}
      />

      <Box className={classes.content}>
        <Box className={classes.btnClose}>
          <IconButton color="secondary" onClick={onClose} className={classes.close}>
            <CloseIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
          </IconButton>
        </Box>

        {widget && widget_key && (
          <>
            {widget?.slug === 'yclients' && <YClientsWidget placeid={`${widget_key}`} className={classes} />}
            {widget?.slug === 'sonlaine' && <SonlineWidget placeid={Number(widget_key)} className={classes} />}
          </>
        )}
        <Box position="absolute" bottom={60 / size.scale} right={40 / size.scale}>
          <IconButton color="secondary" onClick={onOpenWaitingListModal}>
            <Box className={classes.waitingList}>
              Не нашли
              <br />
              подходящее
              <br />
              время?
            </Box>
          </IconButton>
        </Box>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, font, palette }: Theme) => ({
  content: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  close: {
    padding: '0px!important',
  },
  btnClose: {
    zIndex: 100,
    position: 'absolute',
    top: 30 * size.scale,
    right: 60 * size.scale,
    padding: '0px!important',
    boxShadow: '0px 0px 30px 2px rgba(0,0,0,0.3)',
    [down(2000)]: {
      top: 30,
      right: 60,
    },
    [down(1000)]: {
      top: 30 / size.scale,
      right: 60 / size.scale,
    },
  },
  title: {
    fontWeight: 800,
    textAlign: 'center',
    fontSize: 32 * size.scale,
    textTransform: 'uppercase',
    marginBottom: 50 * size.scale,
    color: palette.primary.main,
    [down(2000)]: {
      fontSize: 32,
      marginBottom: 50,
    },
    [down(1000)]: {
      fontSize: 32 / size.scale,
      marginBottom: 50 / size.scale,
    },
  },
  yClientsWidget: {
    width: '100%',
    height: '100%',
    backgroundColor: '#f5f5f5',
  },
  sonlineWidget: {
    width: '100%',
    height: '100%',
    zIndex: '99!important',
    backgroundColor: '#f3f3f3',
  },
  frame: {
    border: '0px!important',
  },
  waitingList: {
    zIndex: 100,
    display: 'flex',
    fontWeight: 700,
    lineHeight: 1.3,
    borderRadius: '50%',
    alignItems: 'center',
    width: 180 * size.scale,
    height: 180 * size.scale,
    justifyContent: 'center',
    fontSize: 22 * size.scale,
    fontFamily: font.secondary,
    color: palette.primary.dark,
    backgroundColor: palette.primary.main,
    boxShadow: '0px 0px 30px 1px rgba(0,0,0,0.3)',
    [down(2000)]: {
      width: 180,
      height: 180,
      fontSize: 22,
    },
    [down(1000)]: {
      width: 180 / size.scale,
      height: 180 / size.scale,
      fontSize: 22 / size.scale,
    },
  },
}))

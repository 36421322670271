import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { PrivateRoute, PublicRoute } from '../components'
import {
  MainPage,
  ReviewPage,
  SigninPage,
  LogoutPage,
  ServicePage,
  RegisterPage,
  NotFoundPage,
  InterviewPage,
  DiagnosticsPage,
  OfferPage,
  GalleryPage,
  AlbumPage,
} from '../pages'

export const RoutesRoot: React.FC = (): JSX.Element => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route key="index" path="/" element={<PrivateRoute component={MainPage} />} />
      <Route key="service" path="/service/:id" element={<PrivateRoute component={ServicePage} />} />
      <Route key="review" path="/review" element={<PrivateRoute component={ReviewPage} />} />
      <Route key="offers" path="/offers" element={<PrivateRoute component={OfferPage} />} />
      <Route key="gallery" path="/gallery" element={<PrivateRoute component={GalleryPage} />} />
      <Route key="album" path="/album/:slug" element={<PrivateRoute component={AlbumPage} />} />
      <Route key="interview" path="/interview" element={<PrivateRoute component={InterviewPage} />} />
      <Route key="register" path="/register" element={<PrivateRoute component={RegisterPage} />} />
      <Route key="diagnostics" path="/diagnostics" element={<PrivateRoute component={DiagnosticsPage} />} />
      <Route key="logout" path="/logout" element={<PrivateRoute component={LogoutPage} />} />
      <Route key="signin" path="/signin" element={<PublicRoute component={SigninPage} />} />
      <Route key="notfound" path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

import React from 'react'
import { Box } from '@mui/material'

interface IYClientsWidget {
  placeid: string
  className: any
}

export const YClientsWidget: React.FC<IYClientsWidget> = ({ placeid, className }): JSX.Element => {
  return (
    <Box className={className.yClientsWidget}>
      <iframe
        width="100%"
        height="100%"
        title="yclients"
        id="ms_booking_iframe"
        className={className.frame}
        src={`https://${placeid}.yclients.com/`}
      />
    </Box>
  )
}

/* eslint-disable max-len */
import React from 'react'
import { Grid, Box, SxProps, Theme } from '@mui/material'
import { keyframes } from '@mui/system'
import { size } from '../../configs'

interface IBurgerIcon {
  width?: number
  height?: number
}

export const BurgerIcon: React.FC<IBurgerIcon> = ({ width = 90, height = 90 }): JSX.Element => (
  <>
    <Grid container direction="column" justifyContent="center" alignItems="flex-end" height={height}>
      <Grid item>
        <Box
          width={width}
          sx={burgerStyles.skeleton}
          height={{ xs: 2 / size.scale, sm: 2, md: 2 * size.scale }}
          mb={{ xs: 1.1 / size.scale, sm: 1.1, md: 1.1 * size.scale }}
        />
      </Grid>

      <Grid item>
        <Box
          width={width - width / 4}
          sx={burgerStyles.skeleton}
          height={{ xs: 2 / size.scale, sm: 2, md: 2 * size.scale }}
          mb={{ xs: 1.1 / size.scale, sm: 1.1, md: 1.1 * size.scale }}
        />
      </Grid>

      <Grid item>
        <Box width={width} sx={burgerStyles.skeleton} height={{ xs: 2 / size.scale, sm: 2, md: 2 * size.scale }} />
      </Grid>
    </Grid>
  </>
)

const animate = keyframes`
  0% {
      background-position: 0 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
`

const burgerStyles: Record<string, SxProps<Theme> | undefined> = {
  skeleton: () => ({
    background: 'linear-gradient(90deg, #FFFFFF, rgba(255, 255, 255, 0.11), #FFFFFF)',
    backgroundSize: '400% 400%',
    animation: `${animate} 6s ease infinite`,
    transform: 'translate3d(0, 0, 0)',
  }),
}

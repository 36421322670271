import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Grid, Theme, useMediaQuery } from '@mui/material'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { formLocale, Modal, SubmitButton, TextInput } from '../../../../components'
import { size } from '../../../../configs'
import { FormProps, ReviewStore, SubmitReviewFormProps } from '../../../../types'
import { reviewStores } from '../../../../api'
import { errorFormToast } from '../../../../toast'
import { useReduxActions, useReduxSelector } from '../../../../hooks'

const reviewModalName = 'reviewModal'

const schema = yup.object().shape({ note: yup.string().required() })

interface IReviewModal {
  show: boolean
  idService: number
  onClose: () => void
  reviewStore: ReviewStore
  onOpenWaitingListModal: () => void
}

export const ReviewModal: React.FC<IReviewModal> = ({
  idService,
  reviewStore,
  show = false,
  onClose = () => {},
  onOpenWaitingListModal = () => {},
}): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { setAlert } = useReduxActions()

  const { serverError } = formLocale

  const title = `Напишите нам свой вопрос<br >или пожелание`

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { data: userData } = useReduxSelector((state) => state.auth)

  const { widget_key = '', widget } = userData || {}

  const hookForm = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<SubmitReviewFormProps>>({
    data: { note: '' },
    processed: false,
  })

  const onCloseModal = () => {
    hookForm.reset()
    onClose()
  }

  const onSubmitForm = async ({ note }: SubmitReviewFormProps) => {
    setFormProps({ ...formProps, processed: true })

    return await reviewStores({ ...reviewStore, note })
      .then((res) => {
        setFormProps({ ...formProps, processed: false })

        if (res.status === 1) {
          setTimeout(() => {
            setAlert({
              alert: {
                title: `Благодарим и будем рады<br />видеть вас снова!`,
                emptyMessage: true,
                message: { info: [''] },
                // actionButtonText: 'Записаться на процедуру',
                // onClose() {
                //   if (widget_key && widget) navigate(`../register?service=${idService}`)
                //   else setTimeout(() => onOpenWaitingListModal(), 400)
                // },
                actionButtonText: 'Продолжить',
                onClose() {
                  navigate(`/?index=main`)
                },
              },
            })
          }, 400)
        }
      })
      .catch((err) => {
        setFormProps({ ...formProps, processed: false })

        if (err.response.data.status === -1 || err.response.data.status === 0) {
          errorFormToast(err.response.data.message)
        } else {
          errorFormToast(serverError)
        }
      })
  }

  return (
    <Modal open={show} maxWidth="md" title={title} name={reviewModalName} onClose={onCloseModal}>
      <Grid container justifyContent="center" className={classes.content}>
        <Grid item>
          <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
            <Grid
              container
              justifyContent="center"
              height={!xl ? 310 * size.scale : md ? 310 / size.scale : 310}
              mb={`${!xl ? 60 * size.scale : md ? 60 / size.scale : 60}px`}
            >
              <Grid item>
                <TextInput
                  rows={6}
                  multiline
                  name="note"
                  form={hookForm}
                  variant="filled"
                  label="Введите текст"
                  disabled={formProps.processed}
                />
              </Grid>
            </Grid>
            <Box
              mx="auto"
              width="100%"
              maxWidth={!xl ? 574 * size.scale : md ? 574 / size.scale : 574}
              mt={`${!xl ? 45 * size.scale : md ? 45 / size.scale : 45}px`}
            >
              <SubmitButton
                fullWidth
                title="Продолжить"
                processed={formProps.processed}
                disabled={formProps.processed}
              />
            </Box>
          </form>
        </Grid>
      </Grid>
    </Modal>
  )
}

const useStyles = makeStyles(({ breakpoints: { down } }: Theme) => ({
  content: {
    marginTop: 10 * size.scale,
    '& .MuiFilledInput-root': {
      paddingTop: `${70}px!important`,
      minWidth: `${800 * size.scale}px!important`,
      maxWidth: `${800 * size.scale}px!important`,
      border: '1px solid #E5E5E5!important',
      fontSize: `${26 * size.scale}px!important`,
      '&:before': {
        display: 'none',
      },
      [down(2000)]: {
        paddingTop: `${40}px!important`,
        minWidth: `${800}px!important`,
        maxWidth: `${800}px!important`,
        fontSize: `${26}px!important`,
      },
      [down(1000)]: {
        paddingTop: `${25}px!important`,
        minWidth: `${800 / size.scale}px!important`,
        maxWidth: `${800 / size.scale}px!important`,
        fontSize: `${26 / size.scale}px!important`,
      },
    },
    '& .MuiFilledInput-input': {
      transform: 'translate(0px, 0px)',
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    '& .MuiFormHelperText-root': {
      color: '#FD3131',
    },
    '& .MuiButton-root': {
      fontSize: `${22 * size.scale}px!important`,
      [down(2000)]: {
        fontSize: `${22}px!important`,
      },
      [down(1000)]: {
        fontSize: `${22 / size.scale}px!important`,
      },
    },
    [down(2000)]: {
      marginTop: 10,
    },
    [down(1000)]: {
      marginTop: 10 / size.scale,
    },
  },
}))

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Theme, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Check } from '@mui/icons-material'
import { TextInput, CheckboxInput, PhoneInput, SubmitButton, formLocale } from '../../../components'
import { FormProps, Product, WaitingListProps } from '../../../types'
import { useReduxActions } from '../../../hooks'
import { errorFormToast } from '../../../toast'
import { size } from '../../../configs'
import { waitingListMake } from '../../../api'

interface IWaitingListForm {
  className: any
  service: Product
  onClose: () => void
}

const schema = yup.object().shape({
  timeslot: yup.string().required(),
  client_phone: yup
    .string()
    .required()
    .transform((value) => value.replace(/\s|\(|\)|\-|\+7/g, ''))
    .matches(/^\d{10}$/, 'Указан некорректный номер телефона'),
  client_fio: yup.string().required(),
  rules: yup.boolean().oneOf([true], 'Необходимо согласиться с условиями').default(false),
})

export const WaitingListForm: React.FC<IWaitingListForm> = ({
  className,
  service,
  onClose = () => {},
}): JSX.Element => {
  const navigate = useNavigate()
  const { setAlert } = useReduxActions()

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { serverError, rulesCheckbox, subscribeSmsCheckbox } = formLocale

  const hookForm = useForm<any>({
    defaultValues: {
      specialist: '',
      timeslot: '',
      client_phone: '',
      client_fio: '',
      rules: false,
      sms_subscribe: false,
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<WaitingListProps>>({
    data: { specialist: '', timeslot: '', client_phone: '', client_fio: '', rules: false, sms_subscribe: false },
    processed: false,
  })

  const onSubmitForm = async (data: WaitingListProps) => {
    setFormProps({ ...formProps, processed: true })

    return await waitingListMake({ ...data, product_id: service.id })
      .then((res) => {
        setFormProps({ ...formProps, processed: false })

        if (res.status === 1) {
          onClose()
          setTimeout(() => {
            setAlert({
              alert: {
                title: 'Ваша запись отправлена салону',
                message: { info: [''] },
                emptyMessage: true,
                onClose() {
                  navigate({ pathname: '../', search: '?index=main' })
                },
              },
            })
          }, 400)
        }
      })
      .catch((err: any) => {
        setFormProps({ ...formProps, processed: false })

        if (err.response.data.status === -1 || err.response.data.status === 0) {
          errorFormToast(err.response.data.message)
        } else {
          errorFormToast(serverError)
        }
      })
  }

  const check = {
    icon: <span className={className.icon} />,
    checked: (
      <span className={className.iconCheck}>
        <Check className={className.check} />
      </span>
    ),
  }

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      <Box width="100%" height={!xl ? 100 * size.scale : md ? 100 / size.scale : 100}>
        <TextInput
          variant="filled"
          name="specialist"
          label="Укажите специалиста*"
          form={hookForm}
          disabled={formProps.processed}
        />
      </Box>
      <Box
        fontSize={!xl ? 20 * size.scale : md ? 20 / size.scale : 20}
        ml={`${!xl ? 10 * size.scale : md ? 10 / size.scale : 10}px`}
        mb={!xl ? 2 * size.scale : md ? 2 / size.scale : 2}
        color="rgba(0, 0, 0, 0.5)"
      >
        * Указывать специалиста не обязательно
      </Box>
      <Box width="100%" height={!xl ? 126 * size.scale : md ? 126 / size.scale : 126}>
        <TextInput
          variant="filled"
          name="timeslot"
          label="Укажите дату и время"
          form={hookForm}
          disabled={formProps.processed}
        />
      </Box>
      <Box width="100%" height={!xl ? 126 * size.scale : md ? 126 / size.scale : 126}>
        <TextInput variant="filled" name="client_fio" label="Ваше Имя" form={hookForm} disabled={formProps.processed} />
      </Box>
      <Box width="100%" height={!xl ? 126 * size.scale : md ? 126 / size.scale : 126}>
        <PhoneInput
          variant="filled"
          name="client_phone"
          label="Ваш телефон"
          form={hookForm}
          disabled={formProps.processed}
        />
      </Box>
      <Box
        width={!xl ? 640 * size.scale : md ? 640 / size.scale : 640}
        height={!xl ? 80 * size.scale : md ? 80 / size.scale : 80}
      >
        <CheckboxInput
          name="rules"
          color="secondary"
          label={rulesCheckbox}
          icon={check.icon}
          checked={check.checked}
          required
          form={hookForm}
        />
      </Box>
      <Box width={!xl ? 640 * size.scale : md ? 640 / size.scale : 640}>
        <CheckboxInput
          form={hookForm}
          icon={check.icon}
          color="secondary"
          name="sms_subscribe"
          checked={check.checked}
          label={subscribeSmsCheckbox}
        />
      </Box>
      <Box
        width="100%"
        mx="auto"
        maxWidth={!xl ? 640 * size.scale : md ? 640 / size.scale : 640}
        mt={`${!xl ? 45 * size.scale : md ? 45 / size.scale : 45}px`}
      >
        <SubmitButton fullWidth disabled={formProps.processed} processed={formProps.processed} title="Продолжить" />
      </Box>
    </form>
  )
}

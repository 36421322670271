import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Theme } from '@mui/material'
import { Modal } from '../../../components'
import { WaitingListForm } from './waiting-list.form'
import { size } from '../../../configs'
import { Product } from '../../../types'

const waitingListModalName = 'waitingListModal'

interface IWaitingListModal {
  service: Product
  show: boolean
  onClose: () => void
}

export const WaitingListModal: React.FC<IWaitingListModal> = ({
  service,
  show = false,
  onClose = () => {},
}): JSX.Element => {
  const classes = useStyles()

  const title = `Запись в лист ожидания<br >На услугу «${service.name}»`

  return (
    <Modal open={show} maxWidth="md" title={title} name={waitingListModalName} onClose={onClose}>
      <Grid container justifyContent="center" className={classes.content}>
        <Grid item>
          <WaitingListForm className={classes} service={service} onClose={onClose} />
        </Grid>
      </Grid>
    </Modal>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  content: {
    '& .MuiFilledInput-root': {
      minWidth: `${640 * size.scale}px!important`,
      border: '1px solid #E5E5E5!important',
      '&:before': {
        display: 'none',
      },
      [down(2000)]: {
        minWidth: `${640}px!important`,
      },
      [down(1000)]: {
        minWidth: `${640 / size.scale}px!important`,
      },
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    '& .MuiFormHelperText-root': {
      color: '#FD3131',
    },
    '& .MuiButton-root': {
      fontSize: `${25 * size.scale}px!important`,
      [down(2000)]: {
        fontSize: `${25}px!important`,
      },
      [down(1000)]: {
        fontSize: `${25 / size.scale}px!important`,
      },
    },
  },
  icon: {
    borderRadius: 0,
    width: 34 * size.scale,
    height: 34 * size.scale,
    border: '1px solid #E5E5E5',
    [down(2000)]: {
      width: 34,
      height: 34,
    },
    [down(1000)]: {
      width: 34 / size.scale,
      height: 34 / size.scale,
    },
  },
  iconCheck: {
    borderRadius: 0,
    width: 34 * size.scale,
    height: 34 * size.scale,
    border: `1px solid ${palette.primary.dark}`,
    [down(2000)]: {
      width: 34,
      height: 34,
    },
    [down(1000)]: {
      width: 34 / size.scale,
      height: 34 / size.scale,
    },
  },
  check: {
    fontSize: `${34 * size.scale}px!important`,
    color: `${palette.primary.dark}!important`,
    [down(2000)]: {
      fontSize: `${34}px!important`,
    },
    [down(1000)]: {
      fontSize: `${34 / size.scale}px!important`,
    },
  },
}))

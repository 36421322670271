import React from 'react'
import { size } from '../../configs'

interface IArrowBackIcon {
  width?: number
  height?: number
}

export const ArrowBackIcon: React.FC<IArrowBackIcon> = ({ width = 80, height = 80 }): JSX.Element => (
  <svg width={`${width}`} height={`${height}`} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        'M26.8127 41.4942L36.3102 50.9069L34.1172 53L21 40L34.1172 27L36.3102 29.0931L26.8127 38.5058L59 ' +
        '38.5058V41.4942L26.8127 41.4942Z'
      }
      fill="black"
    />
  </svg>
)

import React from 'react'
import { Grid, Button, Theme, Box, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Modal } from '../../../../components'
import { size } from '../../../../configs'

const selectLowGradeModalName = 'selectLowGradeModal'

interface ISelectLowGradeModal {
  show: boolean
  onOpenReview: () => void
  onOpenQuestion: () => void
  onClose: () => void
}

export const SelectLowGradeModal: React.FC<ISelectLowGradeModal> = ({
  show = false,
  onClose = () => {},
  onOpenReview = () => {},
  onOpenQuestion = () => {},
}): JSX.Element => {
  const classes = useStyles()

  const title = `Если у вас остались вопросы или<br />пожелания вы можете:`

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const onOpenReviewModal = () => {
    onClose()
    setTimeout(() => onOpenReview(), 400)
  }

  const onOpenQuestionModal = () => {
    onClose()
    setTimeout(() => onOpenQuestion(), 400)
  }

  return (
    <Modal open={show} maxWidth="md" title={title} name={selectLowGradeModalName} onClose={onClose}>
      <Grid container wrap="nowrap" direction="column" alignItems="center">
        <Grid item>
          <Button variant="contained" color="secondary" onClick={onOpenReviewModal} className={classes.btnSecondary}>
            Оставить отзыв
          </Button>
        </Grid>
        <Grid item>
          <Box mt={`${!xl ? 20 * size.scale : md ? 20 / size.scale : 20}px`}>
            <Button variant="contained" color="primary" onClick={onOpenQuestionModal} className={classes.btnPrimary}>
              Ответить на вопросы
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  btnSecondary: {
    fontSize: `${22 * size.scale}px!important`,
    minWidth: `${574 * size.scale}px!important`,
    maxWidth: `${574 * size.scale}px!important`,
    borderColor: `${palette.primary.dark}!important`,
    [down(2000)]: {
      fontSize: `${22}px!important`,
      minWidth: `${574}px!important`,
      maxWidth: `${574}px!important`,
    },
    [down(1000)]: {
      fontSize: `${22 / size.scale}px!important`,
      minWidth: `${574 / size.scale}px!important`,
      maxWidth: `${574 / size.scale}px!important`,
    },
  },
  btnPrimary: {
    fontSize: `${22 * size.scale}px!important`,
    color: `${palette.primary.dark}!important`,
    minWidth: `${574 * size.scale}px!important`,
    maxWidth: `${574 * size.scale}px!important`,
    borderColor: `${palette.primary.dark}!important`,
    [down(2000)]: {
      fontSize: `${22}px!important`,
      minWidth: `${574}px!important`,
      maxWidth: `${574}px!important`,
    },
    [down(1000)]: {
      fontSize: `${22 / size.scale}px!important`,
      minWidth: `${574 / size.scale}px!important`,
      maxWidth: `${574 / size.scale}px!important`,
    },
  },
}))

import { FC, useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

export const Inaction: FC = (): JSX.Element => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [time, setTime] = useState<number>(Date.now())

  useEffect(() => {
    if (pathname !== '/') {
      const touch: HTMLBodyElement = document.body as HTMLBodyElement

      touch.addEventListener('touchstart', () => {
        setTime(Date.now())
      })

      touch.addEventListener('touchenter', () => {
        setTime(Date.now())
      })
    }
  }, [pathname])

  return (
    <Box display="none">
      {pathname !== '/' && (
        <Countdown
          date={time + 10 * 60 * 1000}
          onComplete={() => {
            navigate({ pathname: '../', search: '?index=main' })
          }}
        />
      )}
    </Box>
  )
}

import React from 'react'
import { Grid, Box, IconButton } from '@mui/material'
import clsx from 'clsx'

interface IGrade {
  className: any
  select: number
  onSelectGrade: (id: number) => void
}

export const Grade: React.FC<IGrade> = ({ className, select = 0, onSelectGrade = () => {} }): JSX.Element => {
  return (
    <Grid container wrap="nowrap" justifyContent="space-between" className={className.grade}>
      <Grid item>
        <IconButton
          color={select === 1 ? 'primary' : 'secondary'}
          className={className.btnGrade}
          onClick={() => onSelectGrade(1)}
        >
          <Box className={clsx(className.itemGrade, { [className.selectItemGrade]: select === 1 })}>
            <Box position="relative" top={1.5}>
              1
            </Box>
          </Box>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color={select === 2 ? 'primary' : 'secondary'}
          className={className.btnGrade}
          onClick={() => onSelectGrade(2)}
        >
          <Box className={clsx(className.itemGrade, { [className.selectItemGrade]: select === 2 })}>
            <Box position="relative" top={1.5}>
              2
            </Box>
          </Box>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color={select === 3 ? 'primary' : 'secondary'}
          className={className.btnGrade}
          onClick={() => onSelectGrade(3)}
        >
          <Box className={clsx(className.itemGrade, { [className.selectItemGrade]: select === 3 })}>
            <Box position="relative" top={1.5}>
              3
            </Box>
          </Box>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color={select === 4 ? 'primary' : 'secondary'}
          className={className.btnGrade}
          onClick={() => onSelectGrade(4)}
        >
          <Box className={clsx(className.itemGrade, { [className.selectItemGrade]: select === 4 })}>
            <Box position="relative" top={1.5}>
              4
            </Box>
          </Box>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color={select === 5 ? 'primary' : 'secondary'}
          className={className.btnGrade}
          onClick={() => onSelectGrade(5)}
        >
          <Box className={clsx(className.itemGrade, { [className.selectItemGrade]: select === 5 })}>
            <Box position="relative" top={1.5}>
              5
            </Box>
          </Box>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color={select === 6 ? 'primary' : 'secondary'}
          className={className.btnGrade}
          onClick={() => onSelectGrade(6)}
        >
          <Box className={clsx(className.itemGrade, { [className.selectItemGrade]: select === 6 })}>
            <Box position="relative" top={1.5}>
              6
            </Box>
          </Box>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color={select === 7 ? 'primary' : 'secondary'}
          className={className.btnGrade}
          onClick={() => onSelectGrade(7)}
        >
          <Box className={clsx(className.itemGrade, { [className.selectItemGrade]: select === 7 })}>
            <Box position="relative" top={1.5}>
              7
            </Box>
          </Box>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color={select === 8 ? 'primary' : 'secondary'}
          className={className.btnGrade}
          onClick={() => onSelectGrade(8)}
        >
          <Box className={clsx(className.itemGrade, { [className.selectItemGrade]: select === 8 })}>
            <Box position="relative" top={1.5}>
              8
            </Box>
          </Box>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color={select === 9 ? 'primary' : 'secondary'}
          className={className.btnGrade}
          onClick={() => onSelectGrade(9)}
        >
          <Box className={clsx(className.itemGrade, { [className.selectItemGrade]: select === 9 })}>
            <Box position="relative" top={1.5}>
              9
            </Box>
          </Box>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color={select === 10 ? 'primary' : 'secondary'}
          className={className.btnGrade}
          onClick={() => onSelectGrade(10)}
        >
          <Box className={clsx(className.itemGrade, { [className.selectItemGrade]: select === 10 })}>
            <Box position="relative" top={1.5}>
              10
            </Box>
          </Box>
        </IconButton>
      </Grid>
    </Grid>
  )
}

import { Font } from '../types'

const font: Font = {
  primary: 'Montserrat',
  secondary: 'HelveticaNeueCyr',
  tertiary: '',
  other: '',
}

export default font

import React, { useEffect, useState } from 'react'
import { Grid, Box, Button, CircularProgress, Theme, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSearchParams } from 'react-router-dom'
import { size } from '../../configs'
import { img, LogoIcon } from '../../imgs'

export const InstallPage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const [searchParam, setSearchParam] = useSearchParams()

  const [init, setInit] = useState<boolean>(false)
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null)

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { REACT_APP_VERSION: VERSION } = process.env

  const onInstall = () => {
    deferredPrompt.prompt()
    deferredPrompt.userChoice.then((res: any) => {
      setDeferredPrompt(null)
      if (res.outcome === 'accepted') {
        setDeferredPrompt(null)
      }
    })
  }

  useEffect(() => {
    searchParam.set('index', 'install')
    setSearchParam(searchParam)
    window.addEventListener('beforeinstallprompt', (e: Event) => {
      e.preventDefault()
      setDeferredPrompt(e)
    })
    setTimeout(() => setInit(true), 700)
  }, [])

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.page}>
      <Grid item>
        {init ? (
          <>
            <Box display="flex" justifyContent="center" mb={`${!xl ? 60 * size.scale : md ? 60 / size.scale : 60}px`}>
              <LogoIcon />
            </Box>
            <Box className={classes.text}>
              {deferredPrompt ? (
                'Установка приложения L`oreal'
              ) : (
                <>
                  Приложение L`oreal установлено
                  <br />
                  на вашем устройстве
                </>
              )}
            </Box>
            {!deferredPrompt && <Box className={classes.version}>Версия {VERSION}</Box>}
            {deferredPrompt && (
              <Grid container justifyContent="center">
                <Grid item>
                  <Button variant="contained" color="primary" onClick={onInstall}>
                    Установить
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  page: {
    width: '100%',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundColor: palette.primary.dark,
    backgroundImage: `url(${img.bgReview})`,
    padding: `${50 * size.scale}px ${60 * size.scale}px!important`,
    [down(2000)]: {
      padding: `${50}px ${60}px!important`,
    },
    [down(1000)]: {
      padding: `${50 / size.scale}px ${60 / size.scale}px!important`,
    },
  },
  text: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 40 * size.scale,
    color: palette.primary.main,
    marginBottom: 60 * size.scale,
    [down(2000)]: {
      fontSize: 40,
      marginBottom: 60,
    },
    [down(1000)]: {
      fontSize: 40 / size.scale,
      marginBottom: 60 / size.scale,
    },
  },
  version: {
    fontSize: 36 * size.scale,
    fontWeight: 600,
    textAlign: 'center',
    color: palette.primary.main,
    [down(2000)]: {
      fontSize: 36,
    },
    [down(1000)]: {
      fontSize: 36 / size.scale,
    },
  },
}))

import { FC, ReactNode, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import packageJson from '../../../package.json'
import { apiGetNoCache } from '../../api'
import { checkUpdateAppToast } from '../../toast'

type TMetaJson = { version: string }

const comparisonVersion = (latest: string, current: string) => {
  const newVersion = latest.split(/\./g)
  const oldVersion = current.split(/\./g)

  while (newVersion.length || oldVersion.length) {
    const a = Number(newVersion.shift())
    const b = Number(oldVersion.shift())

    if (a === b) continue

    return a > b || Number.isNaN(b)
  }

  return false
}

export const Buster: FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [isLatestVersion, setIsLatestVersion] = useState<boolean>(false)

  const refreshCacheAndReload = () => {
    if (caches) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name))
      })
    }
    checkUpdateAppToast()
  }

  const versionCheck = () => {
    apiGetNoCache('/meta.json')
      .then(({ data }: AxiosResponse<TMetaJson>) => {
        const latestVersion: string = data.version
        const currentVersion: string = packageJson.version

        const shouldForceRefresh: boolean = comparisonVersion(latestVersion, currentVersion)

        if (shouldForceRefresh) setIsLatestVersion(false)
        else setIsLatestVersion(true)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    versionCheck()
  }, [])

  if (loading) {
    return <></>
  }

  if (!loading && !isLatestVersion) {
    refreshCacheAndReload()
  }

  return <>{children}</>
}

import React, { useState } from 'react'
import { ReviewModal } from './review.modal'
import { SelectLowGradeModal } from './select-low-grade.modal'
import { QuestionModal } from './question.modal'
import { ReviewStore } from '../../../../types'

interface ILowGrade {
  idService: number
  reviewStore: ReviewStore
  showSelectLowGrade: boolean
  onSelectLowGrade: () => void
  onOpenWaitingListModal: () => void
}
export const LowGrade: React.FC<ILowGrade> = ({
  idService,
  reviewStore,
  showSelectLowGrade,
  onSelectLowGrade = () => {},
  onOpenWaitingListModal = () => {},
}): JSX.Element => {
  const [showReview, setShowReview] = useState<boolean>(false)
  const [question, setQuestion] = useState<boolean>(false)

  const onOpenReview = () => setShowReview(true)

  const onCloseReview = () => setShowReview(false)

  const onOpenQuestion = () => setQuestion(true)

  const onCloseQuestion = () => setQuestion(false)

  return (
    <>
      <SelectLowGradeModal
        show={showSelectLowGrade}
        onClose={onSelectLowGrade}
        onOpenReview={onOpenReview}
        onOpenQuestion={onOpenQuestion}
      />

      <ReviewModal
        show={showReview}
        idService={idService}
        onClose={onCloseReview}
        reviewStore={reviewStore}
        onOpenWaitingListModal={onOpenWaitingListModal}
      />

      <QuestionModal
        show={question}
        idService={idService}
        reviewStore={reviewStore}
        onClose={onCloseQuestion}
        onOpenWaitingListModal={onOpenWaitingListModal}
      />
    </>
  )
}

import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Theme } from '@mui/material'
import { Modal } from '../../../../../components'
import { size } from '../../../../../configs'
import { RecommendationForm } from './recommendation.form'
import { ReviewStore } from '../../../../../types'

const recommendationModalName = 'recommendationModal'

interface IRecommendationModal {
  idService: number
  show: boolean
  onClose: () => void
  reviewStore: ReviewStore
  onOpenLocationQRCodeModal: () => void
}

export const RecommendationModal: React.FC<IRecommendationModal> = ({
  idService,
  reviewStore,
  show = false,
  onClose = () => {},
  onOpenLocationQRCodeModal = () => {},
}): JSX.Element => {
  const classes = useStyles()

  return (
    <Modal
      open={show}
      maxWidth="md"
      title="Введите ваши данные и мы направим вам уникальный код для приглашения"
      name={recommendationModalName}
      onClose={onClose}
    >
      <Grid container justifyContent="center" className={classes.content}>
        <Grid item>
          <RecommendationForm
            onClose={onClose}
            className={classes}
            reviewStore={reviewStore}
            onOpenLocationQRCodeModal={onOpenLocationQRCodeModal}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  content: {
    '& .MuiFilledInput-root': {
      minWidth: `${640 * size.scale}px!important`,
      border: '1px solid #E5E5E5!important',
      '&:before': {
        display: 'none',
      },
      [down(2000)]: {
        minWidth: `${640}px!important`,
      },
      [down(1000)]: {
        minWidth: `${640 / size.scale}px!important`,
      },
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    '& .MuiFormHelperText-root': {
      color: '#FD3131',
    },
    '& .MuiButton-root': {
      fontSize: `${22 * size.scale}px!important`,
      [down(2000)]: {
        fontSize: `${22}px!important`,
      },
      [down(1000)]: {
        fontSize: `${22 / size.scale}px!important`,
      },
    },
  },
  icon: {
    borderRadius: 0,
    width: 34 * size.scale,
    height: 34 * size.scale,
    border: '1px solid #E5E5E5',
    [down(2000)]: {
      width: 34,
      height: 34,
    },
    [down(1000)]: {
      width: 34 / size.scale,
      height: 34 / size.scale,
    },
  },
  iconCheck: {
    borderRadius: 0,
    width: 34 * size.scale,
    height: 34 * size.scale,
    border: `1px solid ${palette.primary.dark}`,
    [down(2000)]: {
      width: 34,
      height: 34,
    },
    [down(1000)]: {
      width: 34 / size.scale,
      height: 34 / size.scale,
    },
  },
  check: {
    fontSize: `${34 * size.scale}px!important`,
    color: `${palette.primary.dark}!important`,
    [down(2000)]: {
      fontSize: `${34}px!important`,
    },
    [down(1000)]: {
      fontSize: `${34 / size.scale}px!important`,
    },
  },
}))

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Theme, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AutocompleteInput, PasswordInput, SubmitButton, formLocale, TrackEvent } from '../../components'
import { FormProps, SalonProps } from '../../types'
import { signin } from '../../api'
import { getUser } from '../../store/auth/auth.service'
import { useReduxDispatch } from '../../hooks'
import { errorFormToast } from '../../toast'
import { size } from '../../configs'

type SignInProps = { salon: string; password: string }

const schema = yup.object().shape({
  salon: yup.string().required('Салон не выбран'),
  password: yup.string().required().min(6).max(20),
})

export const SigninForm: React.FC<{ salonsList: SalonProps[] }> = ({ salonsList = [] }): JSX.Element => {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()

  const { serverError } = formLocale

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const hookForm = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [idSalon, setIdSalon] = useState<number>(1)
  const [formProps, setFormProps] = useState<FormProps<SignInProps>>({
    data: { salon: '', password: '' },
    processed: false,
  })

  const onSelectSalon = (value: SalonProps) => setIdSalon(value.id)

  const onSubmitForm = async ({ password }: SignInProps) => {
    setFormProps({ ...formProps, processed: true })
    return await signin(idSalon, password.replace(/\s/g, ''))
      .then(() => {
        TrackEvent({
          eventCategory: 'Authorization',
          eventAction: 'send_r_form',
          eventLabel: 'success',
        })
        setFormProps({ ...formProps, processed: false })
        dispatch(getUser()).then(() => navigate({ pathname: '../', search: '?index=main' }))
      })
      .catch((err: any) => {
        setFormProps({ ...formProps, processed: false })

        if (err.response.data.status === -1 || err.response.data.status === 0) {
          TrackEvent({
            eventCategory: 'Authorization',
            eventAction: 'send_r_form',
            eventLabel: 'unsuccess',
            eventContext: err.response.data.message,
          })
          errorFormToast(err.response.data.message)
        } else {
          errorFormToast(serverError)
        }
      })
  }

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      <Box width="100%" height={!xl ? 126 * size.scale : md ? 126 / size.scale : 126}>
        <AutocompleteInput
          name="salon"
          label="Салоны"
          variant="filled"
          form={hookForm}
          onSelect={onSelectSalon}
          defaultOptions={salonsList}
        />
      </Box>
      <Box width="100%" height={!xl ? 126 * size.scale : md ? 126 / size.scale : 126}>
        <PasswordInput variant="filled" name="password" form={hookForm} disabled={formProps.processed} />
      </Box>
      <Box width="100%" maxWidth={!xl ? 573 * size.scale : md ? 573 / size.scale : 573} mx="auto" my={2}>
        <SubmitButton fullWidth disabled={formProps.processed} processed={formProps.processed} title="Войти" />
      </Box>
    </form>
  )
}

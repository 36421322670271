import React from 'react'
import { Grid, Box, Button, Theme, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { size } from '../../configs'
import { DiagnosticsFinish, Product } from '../../types'
import { useReduxSelector } from '../../hooks'
import { TrackEvent } from '../../components'

interface IFinal {
  diagnosticsFinish: DiagnosticsFinish[]
  onResetDiagnostics: () => void
}

export const Final: React.FC<IFinal> = ({ diagnosticsFinish = [], onResetDiagnostics = () => {} }): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { data: userData } = useReduxSelector((state) => state.auth)

  const { products = [] } = userData || {}

  const onMore = (service: Product) => {
    let idParam

    for (let i = 0; products.length > i; i++) {
      if (products[i].slug === service.slug) {
        idParam = i
        break
      }
    }

    if (idParam) {
      TrackEvent({
        eventCategory: 'Click',
        eventAction: 'result',
        eventLabel: products[idParam].slug,
      })
    }

    navigate(`../service/${idParam}`)
  }

  return (
    <Box className={classes.content}>
      <Box className={classes.title}>Результат диагностики</Box>
      <Box className={classes.text}>Ваша индивидуальная программа ухода</Box>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        height={!xl ? 800 * size.scale : md ? 900 / size.scale : 800}
      >
        <Grid item>
          <Grid
            container
            wrap="nowrap"
            justifyContent="center"
            spacing={!xl ? 2 * size.scale : md ? 2 / size.scale : 2}
          >
            {diagnosticsFinish.map((k: DiagnosticsFinish, _: number) => {
              if (diagnosticsFinish[0].rating !== k.rating) return <></>
              return (
                <Grid item>
                  <Box className={classes.card}>
                    <Box
                      px={`${!xl ? 25 * size.scale : md ? 25 / size.scale : 25}px`}
                      pb={`${!xl ? 30 * size.scale : md ? 40 / size.scale : 30}px`}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        pt={`${!xl ? 30 * size.scale : md ? 30 / size.scale : 30}px`}
                        minHeight={!xl ? 226 * size.scale : md ? 226 / size.scale : 226}
                      >
                        <img src={k.link_interview} alt="" className={classes.img} />
                      </Box>
                      <Box minHeight={!xl ? 310 * size.scale : md ? 310 / size.scale : 310}>
                        <Box className={classes.itemTitle}>{k.name}</Box>
                        <Box className={classes.itemText}>{k.description_interview}</Box>
                      </Box>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.btnSecondary}
                            onClick={() => onMore(k)}
                          >
                            Подробнее
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" className={classes.btnPrimary} onClick={onResetDiagnostics}>
            Пройти заново
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, font, palette }: Theme) => ({
  content: {
    overflow: 'hidden',
    width: 1440 * size.scale,
    height: 1200 * size.scale,
    [down(2000)]: {
      width: 1440,
      height: 1200,
    },
    [down(1000)]: {
      width: 1440 / size.scale,
      height: 1200 / size.scale,
    },
  },
  title: {
    fontWeight: 800,
    textAlign: 'center',
    fontSize: 40 * size.scale,
    textTransform: 'uppercase',
    color: palette.primary.dark,
    marginTop: 35 * size.scale,
    marginBottom: 20 * size.scale,
    [down(2000)]: {
      fontSize: 40,
      marginTop: 35,
      marginBottom: 20,
    },
    [down(1000)]: {
      fontSize: 40 / size.scale,
      marginTop: 40 / size.scale,
      marginBottom: 20 / size.scale,
    },
  },
  text: {
    fontWeight: 400,
    textAlign: 'center',
    fontSize: 30 * size.scale,
    fontFamily: font.secondary,
    color: palette.primary.dark,
    marginBottom: 35 * size.scale,
    [down(2000)]: {
      fontSize: 30,
      marginBottom: 35,
    },
    [down(1000)]: {
      fontSize: 30 / size.scale,
      marginBottom: 60 / size.scale,
    },
  },
  card: {
    width: 420 * size.scale,
    minHeight: 690 * size.scale,
    backgroundColor: '#F4F4F4',
    [down(2000)]: {
      width: 420,
      minHeight: 690,
    },
    [down(1000)]: {
      width: 420 / size.scale,
      minHeight: 720 / size.scale,
    },
  },
  img: {
    width: '100%',
  },
  itemTitle: {
    fontWeight: 800,
    fontSize: 23 * size.scale,
    textTransform: 'uppercase',
    marginTop: 30 * size.scale,
    color: palette.primary.dark,
    marginBottom: 15 * size.scale,
    [down(2000)]: {
      fontSize: 23,
      marginTop: 30,
      marginBottom: 15,
    },
    [down(1000)]: {
      fontSize: 24 / size.scale,
      marginTop: 30 / size.scale,
      marginBottom: 15 / size.scale,
    },
  },
  itemText: {
    fontWeight: 400,
    lineHeight: 1.3,
    fontSize: 22 * size.scale,
    fontFamily: font.secondary,
    color: palette.primary.dark,
    marginBottom: 30 * size.scale,
    [down(2000)]: {
      fontSize: 22,
      marginBottom: 30,
    },
    [down(1000)]: {
      fontSize: 24 / size.scale,
      marginBottom: 30 / size.scale,
    },
  },
  btnSecondary: {
    fontSize: `${20 * size.scale}px!important`,
    minWidth: `${346 * size.scale}px!important`,
    minHeight: `${60 * size.scale}px!important`,
    borderColor: `${palette.primary.dark}!important`,
    [down(2000)]: {
      fontSize: `${20}px!important`,
      minWidth: `${346}px!important`,
      minHeight: `${60}px!important`,
    },
    [down(1000)]: {
      fontSize: `${20 / size.scale}px!important`,
      minWidth: `${346 / size.scale}px!important`,
      minHeight: `${60 / size.scale}px!important`,
    },
  },
  btnPrimary: {
    fontSize: `${22 * size.scale}px!important`,
    color: `${palette.primary.dark}!important`,
    minWidth: `${420 * size.scale}px!important`,
    minHeight: `${80 * size.scale}px!important`,
    borderColor: `${palette.primary.dark}!important`,
    [down(2000)]: {
      fontSize: `${22}px!important`,
      minWidth: `${420}px!important`,
      minHeight: `${80}px!important`,
    },
    [down(1000)]: {
      fontSize: `${22 / size.scale}px!important`,
      minWidth: `${420 / size.scale}px!important`,
      minHeight: `${80 / size.scale}px!important`,
    },
  },
}))

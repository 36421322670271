import React, { useEffect } from 'react'
import { Box, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { size } from '../../configs'
import { img, LogoIcon } from '../../imgs'
import { errorNetworkToast } from '../../toast'
import { useReduxSelector } from '../../hooks'

export const Preloader: React.FC = (): JSX.Element => {
  const classes = useStyles()

  const { init } = useReduxSelector((state) => state.constants)

  useEffect(() => {
    setTimeout(() => {
      if (!navigator.onLine && !init) errorNetworkToast()
    }, 5000)
  })

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.page}>
      <Grid item>
        <Box display="flex" justifyContent="center" mb={`${60 / size.scale}px`}>
          <LogoIcon />
        </Box>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundColor: palette.primary.dark,
    backgroundImage: `url(${img.bgReview})`,
  },
}))

import createPalette from '@mui/material/styles/createPalette'

const palette = createPalette({
  mode: 'light',

  primary: {
    main: '#FFFFFF',
    dark: '#000000',
    contrastText: '#000000',
  },
  secondary: {
    main: '#000000',
    dark: '#FFFFFF',
    contrastText: '#FFFFFF',
  },

  error: { main: '#FFFFFF' },
  warning: { main: '#E75415' },
  info: { main: '#123A6B' },
  success: { main: '#287C48' },

  background: {
    paper: '#000000',
    default: '#FFFFFF',
  },

  text: {
    primary: '#000000',
    secondary: '#FFFFFF',
    disabled: 'rgba(0, 0, 0, .2)',
  },

  action: {
    active: '#FFFFFF',
    hover: '#FFFFFF',
    focus: '#FFFFFF',
    selected: '#FFFFFF',
    disabled: '#FFFFFF',
    disabledBackground: '#C9C9C9',
  },

  divider: 'rgba(18, 58, 107, .25)',
})

export default palette

export interface Font {
  primary: string
  secondary?: string
  tertiary?: string
  other?: string
}

export type Constants = {
  CAPTCHA_SITEKEY?: any
  NOW: Date
}

export type Region = {
  id: number
  slug: string
  name: string
}

export type SalonProps = {
  id: number
  phone: string
  email: string
  name: string
  full_address: string
  region: Region
}

export type messageVariants = 'success' | 'warning' | 'error' | 'info' | 'reset'
export type Message = {
  [variant in messageVariants]?: string[]
}

export type AlertProps = {
  message?: Message
  rootModal?: string
  onClose?(): void
  onAction?(): void
  emptyMessage?: boolean
  closeButton?: boolean
  title?: string
  fullScreen?: boolean
  actionButtonText?: string
}

export enum ApiAnswerStatus {
  SUCCESS = 1,
  BAD_REQUEST = 400,
  UNAUTHENTICATED = 401,
  NEED_FULL_REGISTER = 402,
}

export enum TOKEN {
  PUBLIC = 'token_public',
  PRIVATE = 'token_private',
}

export type SonlineWidgetOptions = {
  placeid: number
}

import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, CircularProgress, Grid, Theme, useMediaQuery } from '@mui/material'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { formLocale, Modal, SubmitButton, TextInput } from '../../../../components'
import { size } from '../../../../configs'
import { FormProps, Review, ReviewStore } from '../../../../types'
import { useReduxActions, useReduxSelector } from '../../../../hooks'
import { reviewAnswers, reviewQuestions, reviewStores } from '../../../../api'
import { errorFormToast } from '../../../../toast'

type QuestionProps = { note: string }

const questionModalName = 'questionModal'

interface IQuestionModal {
  idService: number
  show: boolean
  onClose: () => void
  reviewStore: ReviewStore
  onOpenWaitingListModal: () => void
}

export const QuestionModal: React.FC<IQuestionModal> = ({
  idService,
  reviewStore,
  show = false,
  onClose = () => {},
  onOpenWaitingListModal = () => {},
}): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { setAlert } = useReduxActions()

  const { serverError } = formLocale

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { data: userData } = useReduxSelector((state) => state.auth)

  const { widget_key = '', widget } = userData || {}

  const hookForm = useForm<any>({ defaultValues: {} })

  const [question, setQuestion] = useState<number>(0)
  const [variants, setVariants] = useState<number>(0)

  const [reviewAnswer, setReviewAnswer] = useState<any[]>([])
  const [reviewQuestion, setReviewQuestion] = useState<any[]>([])
  const [dataQuestion, setDataQuestion] = useState<Review[]>([])

  const [formProps, setFormProps] = useState<FormProps<QuestionProps>>({
    data: { note: '' },
    processed: false,
  })

  const loadReviewAnswer = useCallback(() => {
    reviewAnswers().then((res) => {
      if (res.status === 1) {
        setReviewAnswer(res.data)
        setVariants(res.data.length)
      }
    })
  }, [])

  const loadReviewQuestion = useCallback(() => {
    reviewQuestions().then((res) => {
      if (res.status === 1) {
        const initQuestions: Review[] = []

        setReviewQuestion(res.data)

        for (let i = 0; res.data.length > i; i++) {
          initQuestions.push({ review_question_id: null, review_answer_id: null, note: '' })
        }

        setDataQuestion(initQuestions)
      }
    })
  }, [])

  const onSelectVariants = (id: number) => setVariants(id)

  const onCloseModal = () => {
    onClose()
    setVariants(0)
    setQuestion(0)
    loadReviewQuestion()
    loadReviewAnswer()
  }

  const onPushDataQuestion = (note: string) => {
    const dataQuestionCopy: Review[] = Object.assign([], dataQuestion)

    dataQuestionCopy[question].review_answer_id = reviewAnswer[variants].id
    dataQuestionCopy[question].review_question_id = reviewQuestion[question].id
    dataQuestionCopy[question].note = note

    setDataQuestion(dataQuestionCopy)
  }

  const onSubmitForm = ({ note }: QuestionProps, e: any) => {
    if (reviewQuestion.length > question + 1) {
      onPushDataQuestion(note)
      setVariants(reviewAnswer.length)
      setQuestion(question + 1)
      console.log(e)
      hookForm.reset()
    } else {
      setFormProps({ ...formProps, processed: true })

      onPushDataQuestion(note)

      reviewStores({ ...reviewStore, review: dataQuestion })
        .then((res) => {
          setFormProps({ ...formProps, processed: false })

          if (res.status === 1) {
            onCloseModal()
            setTimeout(() => {
              setAlert({
                alert: {
                  title: `Благодарим и будем рады<br />видеть вас снова!`,
                  emptyMessage: true,
                  message: { info: [''] },
                  // actionButtonText: 'Записаться на процедуру',
                  // onClose() {
                  //   if (widget_key && widget) navigate(`../register?service=${idService}`)
                  //   else setTimeout(() => onOpenWaitingListModal(), 400)
                  // },
                  actionButtonText: 'Продолжить',
                  onClose() {
                    navigate(`/?index=main`)
                  },
                },
              })
            }, 400)
          }
        })
        .catch((err) => {
          setFormProps({ ...formProps, processed: false })

          if (err.response.data.status === -1 || err.response.data.status === 0) {
            errorFormToast(err.response.data.message)
          } else {
            errorFormToast(serverError)
          }
        })
    }
  }

  useEffect(() => {
    loadReviewAnswer()
    loadReviewQuestion()
  }, [])

  return (
    <Modal open={show} maxWidth="md" name={questionModalName} onClose={onCloseModal}>
      {reviewAnswer && reviewQuestion ? (
        <>
          <Box className={classes.status}>
            <Box className={classes.number}>
              Вопрос&nbsp;{question + 1}&nbsp;
              <span style={{ color: '#A4A4A4' }}>из&nbsp;{reviewQuestion.length}</span>
            </Box>
            <Grid container wrap="nowrap" spacing={1}>
              {reviewQuestion.map((_, i) => {
                return (
                  <Grid key={`item-status-${i}`} item xs={3}>
                    <Box
                      width="100%"
                      height={!xl ? 10 * size.scale : md ? 10 / size.scale : 10}
                      bgcolor={question >= i ? '#000000' : '#E7E7E7'}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Box className={classes.title}>{reviewQuestion[question]?.name}</Box>
          <Box className={classes.variants}>
            <Grid container wrap="nowrap" justifyContent="space-between">
              {reviewAnswer.map((k, i) => {
                return (
                  <Grid key={`item-answer-${i}`} item>
                    <Box
                      className={clsx(classes.itemVariants, { [classes.selectItemVariants]: variants === i })}
                      onClick={() => onSelectVariants(i)}
                    >
                      {k.name}
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Grid container justifyContent="center" className={classes.content}>
            <Grid item>
              <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
                <Grid
                  container
                  justifyContent="center"
                  height={!xl ? 310 * size.scale : md ? 310 / size.scale : 310}
                  mb={`${!xl ? 60 * size.scale : md ? 60 / size.scale : 60}px`}
                >
                  <Grid item>
                    <TextInput
                      rows={6}
                      multiline
                      name="note"
                      form={hookForm}
                      variant="filled"
                      required={false}
                      label="Введите текст"
                      disabled={formProps.processed}
                    />
                  </Grid>
                </Grid>
                <Box
                  width="100%"
                  maxWidth={!xl ? 574 * size.scale : md ? 574 / size.scale : 574}
                  mx="auto"
                  mt={`${!xl ? 45 * size.scale : md ? 45 / size.scale : 45}px`}
                >
                  <SubmitButton
                    fullWidth
                    disabled={formProps.processed || variants === reviewAnswer.length}
                    processed={formProps.processed}
                    title={reviewQuestion.length > question + 1 ? 'следующий вопрос' : 'Отправить'}
                  />
                </Box>
              </form>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height={!xl ? 800 * size.scale : md ? 800 / size.scale : 800}
        >
          <Grid item>
            <CircularProgress color="secondary" />
          </Grid>
        </Grid>
      )}
    </Modal>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, font, palette }: Theme) => ({
  content: {
    marginTop: 10 * size.scale,
    '& .MuiFilledInput-root': {
      paddingTop: `${70}px!important`,
      border: '1px solid #E5E5E5!important',
      fontSize: `${26 * size.scale}px!important`,
      minWidth: `${950 * size.scale}px!important`,
      maxWidth: `${950 * size.scale}px!important`,
      '&:before': {
        display: 'none',
      },
      [down(2000)]: {
        fontSize: `${26}px!important`,
        paddingTop: `${40}px!important`,
        minWidth: `${950}px!important`,
        maxWidth: `${950}px!important`,
      },
      [down(1000)]: {
        paddingTop: `${25}px!important`,
        fontSize: `${26 / size.scale}px!important`,
        minWidth: `${950 / size.scale}px!important`,
        maxWidth: `${950 / size.scale}px!important`,
      },
    },
    '& .MuiFilledInput-input': {
      transform: 'translate(0px, 0px)',
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    '& .MuiFormHelperText-root': {
      color: '#FD3131',
    },
    '& .MuiButton-root': {
      fontSize: `${22 * size.scale}px!important`,
      [down(2000)]: {
        fontSize: `${22}px!important`,
      },
      [down(1000)]: {
        fontSize: `${22 / size.scale}px!important`,
      },
    },
    [down(2000)]: {
      fontSize: `${22}px!important`,
    },
    [down(1000)]: {
      fontSize: `${22 / size.scale}px!important`,
    },
  },
  title: {
    fontWeight: 800,
    textAlign: 'center',
    fontFamily: font.primary,
    fontSize: 30 * size.scale,
    textTransform: 'uppercase',
    marginBottom: 40 * size.scale,
    [down(2000)]: {
      fontSize: 30,
      marginBottom: 40,
    },
    [down(1000)]: {
      fontSize: 30 / size.scale,
      marginBottom: 40 / size.scale,
    },
  },
  status: {
    width: `${950 * size.scale}px!important`,
    margin: `2px auto ${60 * size.scale}px`,
    [down(2000)]: {
      width: `${950}px!important`,
      margin: `2px auto ${60}px`,
    },
    [down(1000)]: {
      width: `${950 / size.scale}px!important`,
      margin: `2px auto ${60 / size.scale}px`,
    },
  },
  number: {
    fontWeight: 600,
    fontSize: 26 * size.scale,
    fontFamily: font.secondary,
    color: palette.primary.dark,
    marginBottom: 30 * size.scale,
    [down(2000)]: {
      fontSize: 26,
      marginBottom: 30,
    },
    [down(1000)]: {
      fontSize: 26 / size.scale,
      marginBottom: 30 / size.scale,
    },
  },
  variants: {
    width: `${950 * size.scale}px!important`,
    margin: `0 auto ${40 * size.scale}px`,
    [down(2000)]: {
      width: `${950}px!important`,
      margin: `0 auto ${40}px`,
    },
    [down(1000)]: {
      width: `${950 / size.scale}px!important`,
      margin: `0 auto ${40 / size.scale}px`,
    },
  },
  itemVariants: {
    display: 'flex',
    fontWeight: 500,
    lineHeight: 1.3,
    textAlign: 'center',
    alignItems: 'center',
    width: 220 * size.scale,
    height: 90 * size.scale,
    justifyContent: 'center',
    fontSize: 22 * size.scale,
    backgroundColor: '#ECECEC',
    color: palette.primary.dark,
    [down(2000)]: {
      width: 220,
      height: 90,
      fontSize: 22,
    },
    [down(1000)]: {
      width: 220 / size.scale,
      height: 90 / size.scale,
      fontSize: 22 / size.scale,
    },
  },
  selectItemVariants: {
    color: palette.primary.main,
    backgroundColor: palette.primary.dark,
  },
}))

import React from 'react'
import {
  DialogProps as DialogPropsDefault,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery,
  DialogTitle,
  IconButton,
  Button,
  Theme,
  Box,
} from '@mui/material'
import { str } from '../../utils'
import { CloseIcon } from '../../imgs'
import { size } from '../../configs'

export interface DialogProps {
  processed?: boolean
  open?: boolean
  name?: string
  closeButton?: boolean
  actionButtonText?: string
  onClose?: () => void
  onOpen?: () => void
  hidden?: boolean
}

export const Modal: React.FC<DialogProps & DialogPropsDefault> = (props): JSX.Element => {
  const {
    title = undefined,
    name = 'default',
    processed = false,
    onClose = () => {},
    onOpen = () => {},
    fullScreen = false,
    closeButton = true,
    actionButtonText = '',
    children,
    maxWidth = 'sm',
    hidden = false,
  } = props

  const dialogTitleId = `${name}-dialog-title`
  const dialogBodyId = `${name}-dialog-body`

  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  return (
    <Dialog
      {...props}
      title={undefined}
      aria-labelledby={dialogTitleId}
      fullScreen={fullScreen || sm}
      scroll="body"
      maxWidth={maxWidth}
      hidden={hidden}
    >
      {title && <DialogTitle id={dialogTitleId}>{str.normalize(title)}</DialogTitle>}

      {closeButton && (
        <Box position="absolute" top={0} right={0} style={{ zIndex: 2 }}>
          <IconButton color="secondary" disabled={processed} onClick={onClose} style={{ padding: 0 }}>
            <CloseIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
          </IconButton>
        </Box>
      )}

      <DialogContent id={dialogBodyId}>{children}</DialogContent>

      {actionButtonText.length > 0 && (
        <DialogActions>
          <Box mx="auto" mt={2}>
            <Button autoFocus onClick={onClose} variant="contained" color="primary">
              {actionButtonText}
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}

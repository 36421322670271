import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Box, Container, Grid, IconButton, Theme, useMediaQuery } from '@mui/material'
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material'
import Slider from 'react-slick'
import { size } from '../../configs'
import { Layout, Menu } from '../../components'
import { img, ArrowBackIcon, LogoIcon } from '../../imgs'
import { useReduxSelector } from '../../hooks'
import { GradeRoot } from './grade'
import { detectNetwork } from '../../utils'

const NextArrow = (props: any) => {
  const { className, style, onClick, count } = props

  return (
    <Box className={className} style={{ ...style }}>
      <IconButton color="primary" disabled={count} onClick={onClick}>
        <ArrowForwardIos style={{ fontSize: 30 }} />
      </IconButton>
    </Box>
  )
}

const PrevArrow = (props: any) => {
  const { className, style, onClick, count } = props

  return (
    <Box className={className} style={{ ...style }}>
      <IconButton color="primary" disabled={count} onClick={onClick}>
        <ArrowBackIos style={{ fontSize: 30 }} />
      </IconButton>
    </Box>
  )
}

export const ReviewPage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { data: userData } = useReduxSelector((state) => state.auth)

  const { products = [] } = userData || {}

  const settings = {
    speed: 300,
    dots: false,
    infinite: false,
    easing: 'cubic',
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  const [idService, setIdService] = useState<number>(0)
  const [showGradeModal, setShowGradeModal] = useState<boolean>(false)

  const onBack = () => navigate({ pathname: '../', search: '?index=main' })

  const onOpenGrade = (id: number) =>
    detectNetwork(() => {
      setIdService(id)
      setShowGradeModal(true)
    })

  const onCloseGrade = () => setShowGradeModal(false)

  return (
    <Layout>
      <GradeRoot
        idService={idService}
        service={products[idService]}
        showGradeModal={showGradeModal}
        onCloseGrade={onCloseGrade}
      />

      <Container className={classes.content}>
        <Grid container direction="column" justifyContent="space-between" height="100%">
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box width={md ? 236 / size.scale : 236}>
                  <IconButton color="secondary" onClick={onBack}>
                    <ArrowBackIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item>
                <Box className={classes.title} fontSize={!xl ? 40 * size.scale : md ? 40 / size.scale : 40}>
                  Оставьте отзыв
                </Box>
              </Grid>
              <Grid item>
                <Box mr={1}>
                  <LogoIcon width={md ? 235 / size.scale : 235} height={md ? 93 / size.scale : 93} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item width="100%">
            <Box className={classes.slider}>
              {products && (
                <Slider {...settings}>
                  {products.map((k, i) => {
                    if (i % 2 !== 0) return null
                    return (
                      <Fragment key={`slide-${i}`}>
                        {(i % 2 === 0 || i === 0) && (
                          <>
                            <Box
                              className={classes.service}
                              onClick={() => onOpenGrade(i)}
                              mb={`${!xl ? 30 * size.scale : md ? 35 / size.scale : 30}px`}
                            >
                              <img
                                alt=""
                                src={k.link_preview}
                                width={`${!xl ? 870 * size.scale : md ? 870 / size.scale : 870}`}
                                height={`${!xl ? 320 * size.scale : md ? 364 / size.scale : 320}`}
                              />
                              <Box className={classes.textSlide}>{k.name}</Box>
                            </Box>
                            {products[i + 1] && (
                              <Box className={classes.service} onClick={() => onOpenGrade(i + 1)}>
                                <img
                                  alt=""
                                  width={`${!xl ? 870 * size.scale : md ? 870 / size.scale : 870}`}
                                  height={`${!xl ? 320 * size.scale : md ? 364 / size.scale : 320}`}
                                  src={products[i + 1].link_preview}
                                />
                                <Box className={classes.textSlide}>{products[i + 1].name}</Box>
                              </Box>
                            )}
                          </>
                        )}
                      </Fragment>
                    )
                  })}
                </Slider>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Menu />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  content: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundImage: `url(${img.bgReview})`,
    backgroundColor: palette.primary.dark,
    padding: `${40 * size.scale}px ${60 * size.scale}px!important`,
    [down(2000)]: {
      maxWidth: '100%!important',
      padding: `${40}px ${60}px!important`,
    },
    [down(1000)]: {
      padding: `${50 / size.scale}px ${60 / size.scale}px!important`,
    },
  },
  title: {
    fontWeight: 800,
    textTransform: 'uppercase',
    color: palette.primary.main,
  },
  slider: {
    width: '100%',
    padding: `0 ${16 / size.scale}px`,
    '& .slick-list': {
      width: '100%',
    },
    '& .slick-next:before, .slick-prev:before': {
      content: 'none',
      width: '80px!important',
      height: '80px!important',
    },
    '& .slick-next': {
      top: '55%',
      right: -65,
      width: '80px!important',
      height: '80px!important',
    },
    '& .slick-prev': {
      top: '55%',
      left: -37,
      width: '80px!important',
      height: '80px!important',
    },
  },
  service: {
    position: 'relative',
    width: `${870 * size.scale}`,
    height: `${340 * size.scale}`,
    [down(2000)]: {
      width: `${870}`,
      height: `${340}`,
    },
    [down(1000)]: {
      width: `${870 / size.scale}`,
      height: `${364 / size.scale}`,
    },
  },
  textSlide: {
    left: 36 * size.scale,
    bottom: 36 * size.scale,
    position: 'absolute',
    width: 350 * size.scale,
    fontSize: 30 * size.scale,
    textTransform: 'uppercase',
    color: palette.primary.main,
    [down(2000)]: {
      left: 36,
      bottom: 36,
      width: 350,
      fontSize: 30,
    },
    [down(1000)]: {
      left: 36 / size.scale,
      bottom: 36 / size.scale,
      width: 350 / size.scale,
      fontSize: 30 / size.scale,
    },
  },
}))

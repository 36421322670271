import React from 'react'
import { Box, Grid, Fade, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { size } from '../../configs'
import { useReduxSelector } from '../../hooks'

interface ILayout {
  touch?: boolean
  children: React.ReactNode
}

export const Layout: React.FC<ILayout> = ({ touch = false, children }): JSX.Element => {
  const classes = useStyles()

  const { auth } = useReduxSelector((state) => state.auth)

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={clsx(classes.content, { [classes.noTouch]: auth && !touch })}
    >
      <Grid item>
        <Fade in timeout={{ enter: 300 }}>
          <Box className={classes.bodyLayout}>{children}</Box>
        </Fade>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  content: {
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
  },
  noTouch: {
    touchAction: 'none',
  },
  bodyLayout: {
    width: '100vw',
    height: '100vh',
    maxWidth: 1920 * size.scale,
    maxHeight: 1080 * size.scale,
    [down(2000)]: {
      maxWidth: 1920,
      maxHeight: 1080,
    },
    [down(1000)]: {
      maxWidth: 1920 / size.scale,
      maxHeight: 1920 / size.scale,
    },
  },
}))

import { createTheme, Theme } from '@mui/material/styles'
import palette from './palette'
import font from './font'
import { size } from '../configs'

const {
  breakpoints: { down },
} = createTheme()

export const theme: Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1300,
      xl: 2000,
    },
  },
  palette,
  font,
  typography: {
    fontFamily: font.primary,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          backgroundImage: 'none',
        },
      },
    },
    // start - стили бокового меню
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: 613 * size.scale,
          [down(2000)]: {
            maxWidth: 613,
          },
          [down(1000)]: {
            maxWidth: 613 / size.scale,
          },
        },
        paper: {
          width: '100%',
          maxWidth: 613 * size.scale,
          backgroundImage: 'none',
          backgroundColor: `${palette.primary.main}!important`,
          [down(2000)]: {
            maxWidth: 613,
          },
          [down(1000)]: {
            maxWidth: 613 / size.scale,
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, .7)',
        },
      },
    },
    // end - стили бокового меню
    // start - стили модального окна
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '100%',
          background: palette.primary.main,
          margin: 0,
          padding: `${80 / size.scale}px 0 ${60 * size.scale}px!important`,
          borderRadius: 0,
          color: palette.text.primary,
          [down(2000)]: {
            padding: `${80}px 0 ${60}px!important`,
          },
          [down(1000)]: {
            padding: `${80 / size.scale}px 0 ${60 / size.scale}px!important`,
          },
        },
        paperFullScreen: {
          height: 'auto',
        },
        paperWidthLg: {
          maxWidth: `${2000 * size.scale}px!important`,
          [down(2000)]: {
            maxWidth: `${2000}px!important`,
          },
          [down(1000)]: {
            maxWidth: `${2000 / size.scale}px!important`,
          },
        },
        paperWidthMd: {
          maxWidth: `${1200 * size.scale}px!important`,
          [down(2000)]: {
            maxWidth: `${1200}px!important`,
          },
          [down(1000)]: {
            maxWidth: `${1200 / size.scale}px!important`,
          },
        },
        paperWidthSm: {
          maxWidth: `${1000 * size.scale}px!important`,
          [down(2000)]: {
            maxWidth: `${1000}px!important`,
          },
          [down(1000)]: {
            maxWidth: `${1000 / size.scale}px!important`,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          lineHeight: 1.4,
          fontWeight: 800,
          display: 'flex',
          textAlign: 'center',
          position: 'relative',
          justifyContent: 'center',
          fontSize: 32 * size.scale,
          textTransform: 'uppercase',
          color: palette.primary.dark,
          backgroundColor: 'transparent',
          padding: `0 ${120 * size.scale}px ${50 * size.scale}px!important`,
          [down(2000)]: {
            fontSize: 32,
            padding: `0 ${120}px ${50}px!important`,
          },
          [down(1000)]: {
            fontSize: 32 / size.scale,
            padding: `0 ${120 / size.scale}px ${50 / size.scale}px!important`,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          overflow: 'hidden',
          fontSize: 30 * size.scale,
          fontFamily: font.secondary,
          color: palette.primary.dark,
          padding: `0 ${24 * size.scale}px ${20 * size.scale}px`,
          '& ul': {
            textAlign: 'left',
            lineHeight: 1.4,
          },
          '& a': {
            color: palette.text.secondary,
            transition: '0.3s',
            '&:hover': {
              color: palette.text.primary,
            },
          },
          [down(2000)]: {
            fontSize: 30,
            padding: `0 ${24}px ${20}px`,
          },
          [down(1000)]: {
            fontSize: 30 / size.scale,
            padding: `0 ${24 / size.scale}px ${20 / size.scale}px`,
          },
        },
      },
    },
    // end - стили модального окна
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 18 * size.scale,
          marginLeft: 10 * size.scale,
          marginTop: 4 * size.scale,
          color: palette.error.main,
          fontFamily: font.secondary,
          [down(2000)]: {
            fontSize: 18,
            marginLeft: 10,
            marginTop: 4,
          },
          [down(1000)]: {
            fontSize: 18 / size.scale,
            marginLeft: 10 / size.scale,
            marginTop: 4 / size.scale,
          },
        },
      },
    },
    // start - стили линейной загрузки
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
        },
      },
    },
    // end - стили линейной загрузки
    // start - стили поля ввода
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontSize: 26 / size.scale,
          minHeight: 90 / size.scale,
          '&.MuiIconButton-root': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
        },
        input: {},
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          minHeight: 90 * size.scale,
          minWidth: 573 * size.scale,
          fontFamily: font.secondary,
          color: palette.primary.dark,
          paddingRight: 16 * size.scale,
          backgroundColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.main,
          },
          '&:before': {
            height: 0,
          },
          '&.Mui-focused': {
            backgroundColor: palette.primary.main,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.primary.main,
          },
          [down(2000)]: {
            paddingRight: 16,
            minHeight: 90,
            minWidth: 573,
          },
          [down(1000)]: {
            paddingRight: 16 / size.scale,
            minHeight: 90 / size.scale,
            minWidth: 500 / size.scale,
          },
        },
        input: {
          fontWeight: 400,
          fontSize: 26 * size.scale,
          color: palette.primary.dark,
          transform: 'translate(0px, 45%)',
          padding: `0 ${24 * size.scale}px!important`,
          '&:-internal-autofill-selected': {
            background: 'transparent',
            '-webkit-box-shadow': 'inset 0 0 0 50px #FFFFFF!important',
            '-webkit-text-fill-color': '#000000!important',
          },
          '&:-webkit-autofill': {
            background: 'transparent',
            '-webkit-box-shadow': 'inset 0 0 0 50px #FFFFFF!important',
            '-webkit-text-fill-color': '#000000!important',
          },
          [down(2000)]: {
            fontSize: 26,
            padding: `0 ${24}px!important`,
          },
          [down(1000)]: {
            fontSize: 26 / size.scale,
            padding: `0 ${24 / size.scale}px!important`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: font.secondary,
          fontSize: 26 * size.scale,
          transform: `translate(${24 * size.scale}px, 80%)`,
          [down(2000)]: {
            fontSize: 26,
            transform: `translate(${24}px, 80%)`,
          },
          [down(1000)]: {
            fontSize: 26 / size.scale,
            transform: `translate(${24 / size.scale}px, 80%)`,
          },
        },
        shrink: {
          fontWeight: 400,
          fontSize: 22 * size.scale,
          transform: `translate(${24 * size.scale}px, 20%)`,
          [down(2000)]: {
            fontSize: 22,
            transform: `translate(${24}px, 20%)`,
          },
          [down(1000)]: {
            fontSize: 22 / size.scale,
            transform: `translate(${24 / size.scale}px, 20%)`,
          },
        },
        filled: {
          color: palette.primary.dark,
          fontWeight: 400,
          '&.Mui-focused': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
          '&.MuiInputLabel-shrink': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
        },
      },
    },
    // end - стили поля ввода
    // start - стили поля со списком
    MuiSelect: {
      styleOverrides: {
        icon: {
          right: 26 * size.scale,
          color: 'rgba(0, 0, 0, 0.6)',
          [down(2000)]: {
            right: 26,
          },
          [down(1000)]: {
            right: 26 / size.scale,
          },
        },
      },
    },
    // end - стили поля со списком
    // start - стили поля с автодополнением
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: '#000000!important',
          '& .MuiFilledInput-input': {
            transform: 'translate(0px, 20%)',
            paddingLeft: '5px!important',
          },
        },
        listbox: {
          backgroundColor: palette.primary.main,
        },
        endAdornment: {
          marginTop: -10 * size.scale,
          marginRight: 4,
          transition: '0.3s',
          '& .MuiSvgIcon-root': {
            fontSize: 36 * size.scale,
            fill: 'rgba(0, 0, 0, 0.6)!important',
            [down(2000)]: {
              fontSize: 36,
            },
            [down(1000)]: {
              fontSize: 36 / size.scale,
            },
          },
          [down(2000)]: {
            marginTop: -10,
          },
          [down(1000)]: {
            marginTop: 4,
          },
        },
        inputRoot: {
          paddingTop: 10,
          paddingRight: 30,
        },
        paper: {
          marginLeft: -0.5,
          width: 573 * size.scale,
          backgroundColor: palette.primary.main,
          [down(2000)]: {
            width: 573,
          },
          [down(1000)]: {
            width: 573 / size.scale,
          },
        },
        loading: {
          fontSize: 24 * size.scale,
          fontWeight: 500,
          color: palette.primary.dark,
          [down(2000)]: {
            fontSize: 24,
          },
          [down(1000)]: {
            fontSize: 24 / size.scale,
          },
        },
        noOptions: {
          fontSize: 24 * size.scale,
          fontWeight: 500,
          color: palette.primary.dark,
          [down(2000)]: {
            fontSize: 24,
          },
          [down(1000)]: {
            fontSize: 24 / size.scale,
          },
        },
      },
    },
    // end - стили поля с автодополнением
    // start - стили кнопки
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          fontWeight: 600,
          borderRadius: 0,
          minWidth: 500 * size.scale,
          fontSize: 26 * size.scale,
          minHeight: 90 * size.scale,
          boxShadow: 'none!important',
          color: palette.primary.main,
          backgroundColor: 'transparent',
          border: `1px solid ${palette.primary.main}`,
          '&:hover': {
            color: palette.primary.main,
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled': {
            color: `${palette.action.disabled}!important`,
            backgroundColor: 'transparent',
          },
          [down(2000)]: {
            fontSize: 26,
            minHeight: 90,
            minWidth: 500,
          },
          [down(1000)]: {
            fontSize: 26 / size.scale,
            minHeight: 90 / size.scale,
            minWidth: 500 / size.scale,
          },
        },
        containedSecondary: {
          fontSize: 26 * size.scale,
          fontWeight: 600,
          borderRadius: 0,
          borderColor: palette.primary.main,
          borderWidth: 1,
          borderStyle: 'solid',
          minWidth: 500 * size.scale,
          minHeight: 90 * size.scale,
          color: palette.primary.main,
          backgroundColor: palette.primary.dark,
          boxShadow: 'none!important',
          '&:hover': {
            color: palette.primary.main,
            backgroundColor: palette.primary.dark,
          },
          '&.Mui-disabled': {
            color: `${palette.action.disabled}!important`,
            backgroundColor: `${palette.action.disabledBackground}!important`,
          },
          [down(2000)]: {
            fontSize: 26,
            minHeight: 90,
            minWidth: 500,
          },
          [down(1000)]: {
            fontSize: 26 / size.scale,
            minHeight: 90 / size.scale,
            minWidth: 500 / size.scale,
          },
        },
      },
    },
    // end - стили кнопки
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 5,
          marginLeft: -9,
          '& .MuiTypography-root': {
            fontWeight: 300,
            lineHeight: 1.2,
            marginLeft: 10 * size.scale,
            fontFamily: font.secondary,
            color: palette.primary.dark,
            fontSize: `${25 * size.scale}px!important`,
            '& a': {
              transition: '0.2s',
              textDecoration: 'uppercase!important',
              color: `${palette.primary.dark}!important`,
              textDecorationColor: 'rgba(0, 0, 0, 0.6)!important',
              '&:hover': {
                color: `${palette.text.primary}!important`,
              },
            },
            [down(2000)]: {
              marginLeft: 10,
              fontSize: `${25}px!important`,
            },
            [down(1000)]: {
              marginLeft: 0,
              fontSize: `${25 / size.scale}px!important`,
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {},
        paper: {
          fontSize: 26 * size.scale,
          fontWeight: 500,
          maxWidth: 600 * size.scale,
          lineHeight: 1.4,
          border: `1px solid #6C6C6C`,
          backgroundColor: '#222222',
          backgroundImage: 'none',
          color: palette.text.secondary,
          [down(2000)]: {
            fontSize: `${26}px!important`,
            maxWidth: 600,
          },
          [down(1000)]: {
            maxWidth: 600 / size.scale,
            fontSize: `${26 / size.scale}px!important`,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          minWidth: 570 * size.scale,
          borderRadius: 0,
          marginLeft: 3,
          [down(2000)]: {
            minWidth: 570,
          },
          [down(1000)]: {
            minWidth: 570 / size.scale,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          width: 570 / size.scale,
          borderRadius: 0,
          background: palette.primary.main,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          transition: '0.1s',
          fontSize: `${22 / size.scale}px!important`,
          fontFamily: `${font.secondary}!important`,
          color: `${palette.primary.dark}!important`,
          backgroundColor: 'transparent!important',
          '&.Mui-selected': {
            backgroundColor: 'rgb(0, 0, 0, 0.2)!important',
          },
          '&:hover': {
            backgroundColor: 'transparent!important',
          },
        },
      },
    },
  },
})

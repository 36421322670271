import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Grid, Button, Theme, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'
import { Modal } from '../../../../components'
import { size } from '../../../../configs'
import { useReduxSelector } from '../../../../hooks'

const locationQRCodeModalName = 'locationQRCodeModal'

interface ILocationQRCodeModal {
  idService: number
  show: boolean
  onClose: () => void
  onOpenWaitingListModal: () => void
}

export const LocationQRCodeModal: React.FC<ILocationQRCodeModal> = ({
  idService,
  show = false,
  onClose = () => {},
  onOpenWaitingListModal = () => {},
}): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { data: userData } = useReduxSelector((state) => state.auth)

  const { widget_key = '', widget, yandex_card = '', two_gis_card = '' } = userData || {}

  const onRegister = () => {
    if (widget_key && widget) {
      navigate(`../register?service=${idService}`)
    } else {
      onClose()
      setTimeout(() => onOpenWaitingListModal(), 400)
    }
  }

  return (
    <Modal
      open={show}
      maxWidth="md"
      title={
        `Будем благодарны, если Вы уделите нам<br />пару минут и оставите отзыв о нас<br />` +
        `на «Яндекс.Карты» и «2Гис».`
      }
      name={locationQRCodeModalName}
      onClose={onClose}
    >
      <Grid container justifyContent="space-evenly" className={classes.content}>
        {yandex_card && (
          <Grid item>
            <Box p={`${!xl ? 22 * size.scale : md ? 22 / size.scale : 22}px`} bgcolor="#F5F5F5">
              <QRCode
                value={yandex_card}
                size={!xl ? 244 * size.scale : md ? 244 / size.scale : 244}
                viewBox={`0 0 ${!xl ? 244 * size.scale : md ? 244 / size.scale : 244} ${
                  !xl ? 244 * size.scale : md ? 244 / size.scale : 244
                }`}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              />
            </Box>
            <Box className={classes.text}>Яндекс.Карты</Box>
          </Grid>
        )}
        {two_gis_card && (
          <Grid item>
            <Box p={`${!xl ? 22 * size.scale : md ? 22 / size.scale : 22}px`} bgcolor="#F5F5F5">
              <QRCode
                value={two_gis_card}
                size={!xl ? 244 * size.scale : md ? 244 / size.scale : 244}
                viewBox={`0 0 ${!xl ? 244 * size.scale : md ? 244 / size.scale : 244} ${
                  !xl ? 244 * size.scale : md ? 244 / size.scale : 244
                }`}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              />
            </Box>
            <Box className={classes.text}>2Гис</Box>
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="center" className={classes.btn}>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={onRegister}>
            Записаться на процедуру
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, font, palette }: Theme) => ({
  content: {
    padding: `0 ${40 * size.scale}px`,
    [down(2000)]: {
      padding: `0 ${40}px`,
    },
    [down(1000)]: {
      padding: `0 ${40 / size.scale}px`,
    },
  },
  text: {
    fontWeight: 500,
    textAlign: 'center',
    fontSize: 28 * size.scale,
    marginTop: 30 * size.scale,
    fontFamily: font.secondary,
    color: palette.primary.dark,
    [down(2000)]: {
      fontSize: 28,
      marginTop: 30,
    },
    [down(1000)]: {
      fontSize: 28 / size.scale,
      marginTop: 30 / size.scale,
    },
  },
  btn: {
    marginTop: 80 * size.scale,
    '& .MuiButton-root': {
      fontSize: `${22 * size.scale}px!important`,
      minWidth: `${574 * size.scale}px!important`,
      maxWidth: `${574 * size.scale}px!important`,
      [down(2000)]: {
        fontSize: `${22}px!important`,
        minWidth: `${574}px!important`,
        maxWidth: `${574}px!important`,
      },
      [down(1000)]: {
        fontSize: `${22 / size.scale}px!important`,
        minWidth: `${574 / size.scale}px!important`,
        maxWidth: `${574 / size.scale}px!important`,
      },
    },
    [down(2000)]: {
      marginTop: 80,
    },
    [down(1000)]: {
      marginTop: 80 / size.scale,
    },
  },
}))

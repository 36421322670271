import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Container, Grid, IconButton, Theme, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import Moment from 'react-moment'
import { ArrowBackIcon, img, LogoIcon } from '../../imgs'
import { size } from '../../configs'
import { Layout } from '../../components'
import { getOffers } from '../../api'
import { Offers } from '../../types'
import { str } from '../../utils'

interface ISlide extends Offers {
  className: any
}

const { REACT_APP_API_URL: API_URL, DEBUG } = process.env

const Slide: FC<ISlide> = ({ className, name, start_at, end_at, description, media_primary }): JSX.Element => {
  const image = `${DEBUG ? test : API_URL}${media_primary.src}`

  return (
    <Box
      className={className.slide}
      sx={{ backgroundImage: `url(${image})` }}
      width={{ xs: 566 / size.scale, md: 566, xl: 566 * size.scale }}
      height={{ xs: 814 / size.scale, md: 814, xl: 814 * size.scale }}
    >
      <Box
        height={{ xs: 814 / size.scale - 30, md: 814 - 60, xl: 814 * size.scale - 120 }}
        p={{ xs: `${30 / size.scale}px!important`, md: `${30}px!important`, xl: `${30 * size.scale}px!important` }}
      >
        <Grid container direction="column" justifyContent="space-between" height="100%" position="relative" zIndex={2}>
          <Grid item>
            <Box className={className.slideTitle}>{name}</Box>
            <Box className={className.slideDate}>
              с <Moment format="DD MMMM">{start_at}</Moment> по <Moment format="DD MMMM">{end_at}</Moment>
            </Box>
          </Grid>
          <Grid item>
            <Box className={className.slideText}>{str.normalize(description.replace('<p>', ''))}</Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export const OfferPage: FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()

  const settings = {
    speed: 300,
    dots: false,
    infinite: false,
    easing: 'cubic',
    slidesToShow: 3,
    slidesToScroll: 1,
  }

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const [offers, setOffers] = useState<Offers[]>([])
  const [isLoad, setIsLoad] = useState<boolean>(false)

  const onBack = () => navigate({ pathname: '../', search: '?index=main' })

  const uploadOffers = useCallback(() => {
    getOffers().then((res) => {
      setOffers(res.data)
      setIsLoad(true)
    })
  }, [])

  useEffect(() => {
    uploadOffers()
  }, [])

  return (
    <Layout>
      <Box className={classes.content}>
        <Container className={classes.container}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box width={md ? 236 / size.scale : 236}>
                <IconButton color="secondary" onClick={onBack}>
                  <ArrowBackIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.title} fontSize={!xl ? 40 * size.scale : md ? 40 / size.scale : 40}>
                Специальные предложения
              </Box>
            </Grid>
            <Grid item>
              <Box mr={1}>
                <LogoIcon width={md ? 235 / size.scale : 235} height={md ? 93 / size.scale : 93} />
              </Box>
            </Grid>
          </Grid>
        </Container>

        {Array.isArray(offers) && offers.length > 0 ? (
          <Box className={classes.slider}>
            <Slider {...settings}>
              {offers.map((k: Offers, _: number) => (
                <Slide className={classes} {...k} />
              ))}
            </Slider>
          </Box>
        ) : (
          isLoad && (
            <Grid container justifyContent="center" alignItems="center" height="70vh">
              <Grid item className={classes.noOffers}>
                Сейчас у салона нет спец. предложений.
              </Grid>
            </Grid>
          )
        )}
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  content: {
    width: '100%',
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundImage: `url(${img.bgReview})`,
  },
  container: {
    maxWidth: '100%!important',
    padding: `${40 * size.scale}px ${60 * size.scale}px!important`,
    [down(2000)]: { padding: `${40}px ${60}px!important` },
    [down(1000)]: { padding: `${50 / size.scale}px ${60 / size.scale}px!important` },
  },
  title: {
    fontWeight: 800,
    textTransform: 'uppercase',
    color: palette.primary.main,
  },
  slider: {
    width: '93.5%',
    height: '100%',
    '& .slick-list': { overflow: 'visible!important' },
    marginLeft: `${72 * size.scale}px!important`,
    '& .slick-next, .slick-prev': { display: 'none' },
    '& .slick-next:before, .slick-prev:before': { display: 'none' },
    [down(2000)]: { marginLeft: `${72}px!important` },
    [down(1000)]: { marginLeft: `${72 / size.scale}px!important` },
  },
  slide: {
    position: 'relative',
    backgroundSize: '100% auto',
    border: '2px solid #363636',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&:after': {
      left: 0,
      zIndex: 1,
      bottom: 0,
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)',
    },
  },
  slideTitle: {
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: `${30 * size.scale}px!important`,
    [down(2000)]: { fontSize: `${30}px!important` },
    [down(1000)]: { fontSize: `${30 / size.scale}px!important` },
  },
  slideDate: {
    fontWeight: 600,
    fontSize: `${25 * size.scale}px!important`,
    padding: `${20 * size.scale}px 0 ${40 * size.scale}px!important`,
    [down(2000)]: { padding: `${20}px 0 ${40}px!important`, fontSize: `${25}px!important` },
    [down(1000)]: {
      padding: `${20 / size.scale}px 0 ${40 / size.scale}px!important`,
      fontSize: `${25 / size.scale}px!important`,
    },
  },
  slideText: {
    fontWeight: 500,
    // overflow: 'hidden',
    // display: '-webkit-box',
    // height: 156 * size.scale,
    // '-webkit-line-clamp': '6',
    // '-webkit-box-orient': 'vertical',
    fontSize: `${20 * size.scale}px!important`,
    [down(2000)]: {
      fontSize: `${20}px!important`,
      // height: 156,
    },
    [down(1000)]: {
      fontSize: `${20 / size.scale}px!important`,
      // height: 156 / size.scale,
    },
  },
  noOffers: {
    fontWeight: 600,
    fontSize: `${36 * size.scale}px!important`,
    [down(2000)]: { fontSize: `${36}px!important` },
    [down(1000)]: { fontSize: `${36 / size.scale}px!important` },
  },
}))

import React, { useCallback, useEffect } from 'react'
import * as ls from 'local-storage'
import { RoutesRoot } from './routes'
import { useReduxDispatch, useReduxSelector } from '../hooks'
import { getUser } from '../store/auth/auth.service'
import { getConst } from '../store/constants/constants.service'
import { Inaction, Preloader } from '../components'
import { ModalRoot } from './modal-root'
import { NoUpdateAppToast } from '../toast'

export const AppPwa: React.FC = (): JSX.Element => {
  const dispatch = useReduxDispatch()

  const { init: isConstants } = useReduxSelector((state) => state.constants)

  const uploadUserInfo = useCallback(() => {
    dispatch(getUser())
    setTimeout(() => uploadUserInfo(), 1000 * 60 * 60) // 1 час
  }, [])

  useEffect(() => {
    dispatch(getConst())
    uploadUserInfo()
    if (ls.get('__check_update')) {
      NoUpdateAppToast()
      ls.remove('__check_update')
    }
  }, [])

  if (!isConstants) {
    return <Preloader />
  }

  return (
    <>
      <Inaction />
      <ModalRoot />
      <RoutesRoot />
    </>
  )
}

import React from 'react'
import { ToastContainer } from 'react-toastify'
import { Buster, DisplayMode } from '../components'
import { AppPwa } from './app-pwa'
import { TOAST } from '../types'
import { InstallPage } from '../pages'

export const App: React.FC = (): JSX.Element => {
  return (
    <DisplayMode browserElement={InstallPage}>
      <Buster>
        <ToastContainer containerId={TOAST.GLOBAL_CONTAINER_ID} />
        <AppPwa />
      </Buster>
    </DisplayMode>
  )
}

import React from 'react'
import { Grid, Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { img, LogoIcon } from '../../imgs'
import { size } from '../../configs'

export const ErrorFallback: React.FC = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.page}>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <LogoIcon />
          </Grid>
        </Grid>
        <Box width="100%" textAlign="center" my={3} className={classes.title}>
          Что-то пошло не так...
        </Box>
        <Box width="100%" textAlign="center" className={classes.text}>
          Перезапустите приложение для продолжения работы.
        </Box>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  page: {
    height: '100vh',
    padding: '0 15px',
    backgroundSize: 'cover',
    color: palette.primary.main,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundImage: `url(${img.bgReview})`,
  },
  title: {
    fontSize: 50 / size.scale,
    fontWeight: 600,
  },
  text: {
    fontWeight: 500,
    lineHeight: 1.4,
    fontSize: 30 / size.scale,
  },
}))

import React, { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, IconButton, Theme, useMediaQuery, useScrollTrigger } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Layout } from '../../components'
import { size } from '../../configs'
import { ArrowBackIcon, img, LogoIcon } from '../../imgs'
import { getGallery } from '../../api'
import { Gallery } from '../../types'

interface IAlbum extends Gallery {
  className: any
  onClick: () => void
}

const test = 'https://losalons-2023.xtest.atoms.ru/endpoint'

const Album: FC<IAlbum> = ({ className, onClick, name, media }): JSX.Element => {
  const { REACT_APP_API_URL: API_URL, DEBUG } = process.env

  const image = media.filter((k) => k.type === 'image')
  const video = media.filter((k) => k.type === 'video')
  const background = image.length > 0 ? image[0].src : ''

  const backgroundImage = image.length > 0 ? `url(${DEBUG ? test : API_URL}/${background})` : `url(${img.previewAlbum})`

  return (
    <Box
      onClick={onClick}
      sx={{ backgroundImage }}
      className={image.length > 0 ? className.album : className.previewAlbum}
    >
      <Grid container direction="column" justifyContent="flex-end" zIndex={2} position="relative" height="100%">
        <Grid item>
          <Box p={{ xs: `${40 / size.scale}px`, md: `${40}px`, xl: `${40 * size.scale}px` }}>
            <Box className={className.albumTitle}>{name}</Box>
            <Box className={className.albumText}>
              {image.length} Фото, {video.length} Видео
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export const GalleryPage: FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const [gallery, setGallery] = useState<Gallery[]>([])
  const [isLoad, setIsLoad] = useState<boolean>(false)

  const onBack = () => navigate({ pathname: '../', search: '?index=main' })

  const onOpenAlbum = (slug: string) => navigate(`../album/${slug}`)

  const uploadGallery = useCallback(() => {
    getGallery().then((res) => {
      setGallery(res.data)
      setIsLoad(true)
    })
  }, [])

  useEffect(() => {
    uploadGallery()
  }, [])

  return (
    <Layout touch>
      <Box className={classes.content}>
        <Container className={classes.container}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box width={md ? 236 / size.scale : 236}>
                <IconButton color="secondary" onClick={onBack}>
                  <ArrowBackIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.title} fontSize={!xl ? 40 * size.scale : md ? 40 / size.scale : 40}>
                Галерея
              </Box>
            </Grid>
            <Grid item>
              <Box mr={1}>
                <LogoIcon width={md ? 235 / size.scale : 235} height={md ? 93 / size.scale : 93} />
              </Box>
            </Grid>
          </Grid>

          <Container className={classes.albums}>
            {Array.isArray(gallery) && gallery.length > 0 ? (
              <Grid container spacing={1}>
                {gallery.map((k: Gallery, i: number) => (
                  <Grid item xs={4}>
                    <Album className={classes} onClick={() => onOpenAlbum(k.slug)} {...k} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              isLoad && (
                <Grid container justifyContent="center" alignItems="center" height="65vh">
                  <Grid item className={classes.noGallery}>
                    Галерея пуста.
                  </Grid>
                </Grid>
              )
            )}
          </Container>
        </Container>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  content: {
    width: '100%',
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundImage: `url(${img.bgReview})`,
  },
  container: {
    height: '100%',
    overflow: 'hidden',
    maxWidth: '100%!important',
    padding: `${40 * size.scale}px ${60 * size.scale}px!important`,
    [down(2000)]: { padding: `${40}px ${60}px!important` },
    [down(1000)]: { padding: `${50 / size.scale}px ${60 / size.scale}px!important` },
  },
  title: {
    fontWeight: 800,
    textTransform: 'uppercase',
    color: palette.primary.main,
  },
  albums: {
    height: '100%',
    padding: '0 8px!important',
    maxWidth: '100%!important',
    marginTop: `${72 * size.scale}px!important`,
    [down(2000)]: { marginTop: `${72}px!important` },
    [down(1000)]: { marginTop: `${72 / size.scale}px!important` },
  },
  album: {
    width: '100%',
    position: 'relative',
    height: 586 * size.scale,
    backgroundSize: '100% auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&:after': {
      left: 0,
      zIndex: 1,
      bottom: 0,
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)',
    },
    [down(2000)]: { height: 586 },
    [down(1000)]: { height: 586 / size.scale },
  },
  previewAlbum: {
    width: '100%',
    position: 'relative',
    height: 586 * size.scale,
    backgroundSize: '80% auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&:after': {
      left: 0,
      zIndex: 1,
      bottom: 0,
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)',
    },
    [down(2000)]: { height: 586 },
    [down(1000)]: { height: 586 / size.scale },
  },
  albumTitle: {
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: `${30 * size.scale}px!important`,
    marginBottom: 28 * size.scale,
    [down(2000)]: { fontSize: `${30}px!important`, marginBottom: 28 },
    [down(1000)]: { fontSize: `${30 / size.scale}px!important`, marginBottom: 28 / size.scale },
  },
  albumText: {
    fontWeight: 600,
    fontSize: `${25 * size.scale}px!important`,
    [down(2000)]: { fontSize: `${25}px!important` },
    [down(1000)]: { fontSize: `${25 / size.scale}px!important` },
  },
  noGallery: {
    fontWeight: 600,
    fontSize: `${36 * size.scale}px!important`,
    [down(2000)]: { fontSize: `${36}px!important` },
    [down(1000)]: { fontSize: `${36 / size.scale}px!important` },
  },
}))

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { KEYMOVE } from '../../types'
import { usePressDetect, useTouchDetect } from '../../hooks'

interface IDetectLogout {
  touchElement: string
}

export const DetectLogout: React.FC<IDetectLogout> = ({ touchElement }) => {
  const navigate = useNavigate()
  const { press, watch: pressWatch } = usePressDetect()
  const { touch, watch: touchWatch } = useTouchDetect(touchElement)

  const [logoutCountTouch, setLogoutCountTouch] = useState<number>(0)
  const [logoutCountPress, setLogoutCountPress] = useState<number>(0)

  useEffect(() => {
    if (touch && touch === KEYMOVE.TAP) setLogoutCountTouch(logoutCountTouch + 1)
  }, [touchWatch])

  useEffect(() => {
    if (logoutCountTouch > 4) navigate('../logout')
  }, [logoutCountTouch])

  useEffect(() => {
    if (press && press === KEYMOVE.ESC) setLogoutCountPress(logoutCountPress + 1)
  }, [pressWatch])

  useEffect(() => {
    if (logoutCountPress > 4) navigate('../logout')
  }, [logoutCountPress])

  return <></>
}

import React from 'react'
import { Button, ButtonProps, CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

type ButtonPropsCustom = ButtonProps & { processed?: boolean }

export const SubmitButton: React.FC<ButtonPropsCustom> = ({
  className,
  onClick = () => {},
  processed = false,
  disabled = false,
  title,
  type = 'submit',
  variant = 'contained',
  color = 'secondary',
  ...props
}) => {
  const classes = useStyles()

  return (
    <Button
      className={className}
      onClick={onClick}
      type={type}
      variant={variant}
      color={color}
      disabled={disabled || processed}
      {...props}
    >
      {title || props.children}
      {processed && <CircularProgress size={16} className={classes.buttonProgress} />}
    </Button>
  )
}

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: `${palette.primary.main}!important`,
    marginTop: spacing(-3 / 2),
    marginLeft: spacing(-3 / 2),
  },
}))

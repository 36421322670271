import logo from './logo.svg'
import bgReview from './bg-review.png'
import bgInterview from './bg-interview.png'
import bgDiagnostics from './bg-diagnostics.png'
import previewAlbum from './preview-album.png'

export * from './svg'

export const img = {
  logo,
  bgReview,
  bgInterview,
  previewAlbum,
  bgDiagnostics,
}

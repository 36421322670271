import React, { useState } from 'react'
import { Box, Container, Grid, IconButton, Button, Theme, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { formLocale, Layout, Modal } from '../../components'
import { ArrowBackIcon, img, LogoIcon } from '../../imgs'
import { size } from '../../configs'
import { useReduxActions } from '../../hooks'
import { ratingSalonStore } from '../../api'
import { errorFormToast } from '../../toast'

export const InterviewPage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { setAlert } = useReduxActions()

  const { serverError } = formLocale

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const [select, setSelect] = useState<number>(0)
  const [modal, setModal] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)

  const onBack = () => navigate({ pathname: '../', search: '?index=main' })

  const onSelect = (grade: number) => {
    setSelect(grade)
  }

  const onOpenModal = () => {
    if (modal) onBack()
    setModal(!modal)
  }

  const onSubmit = () => {
    if (select === 0) {
      setAlert({
        alert: {
          message: { info: ['Оценка для салона не выбрана'] },
        },
      })
    } else {
      setDisable(true)

      ratingSalonStore({ value: select })
        .then((res) => {
          setDisable(false)

          if (res.status === 1) {
            onOpenModal()
          }
        })
        .catch((err) => {
          setDisable(false)

          if (err.response.data.status === -1 || err.response.data.status === 0) {
            errorFormToast(err.response.data.message)
          } else {
            errorFormToast(serverError)
          }
        })
    }
  }

  return (
    <Layout>
      <Modal open={modal} onClose={onOpenModal}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Box
              sx={{
                mt: !xl ? `${20 * size.scale}px` : md ? `${20 / size.scale}px` : `${20}px`,
                fontWeight: 900,
                fontSize: !xl ? 40 * size.scale : md ? 40 / size.scale : 40,
                mb: `${!xl ? 60 * size.scale : md ? 60 / size.scale : 60}px`,
                textTransform: 'uppercase',
              }}
            >
              Ваша оценка принята
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              sx={{ minWidth: !xl ? 300 * size.scale : md ? 300 / size.scale : 300 }}
              onClick={onOpenModal}
            >
              ОК
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Container className={classes.content}>
        <Grid container direction="column" justifyContent="space-between">
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box width={!xl ? 236 * size.scale : md ? 236 / size.scale : 236}>
                  <IconButton color="secondary" onClick={onBack}>
                    <ArrowBackIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item>
                <LogoIcon width={md ? 235 / size.scale : 235} height={md ? 93 / size.scale : 93} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" height="calc(100% - 56px)">
          <Grid item>
            <Box className={classes.title}>Оцените уровень нашей работы</Box>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              mt={`${!xl ? 64 * size.scale : md ? 64 / size.scale : 64}px`}
              mb={`${!xl ? 124 * size.scale : md ? 124 / size.scale : 124}px`}
            >
              <Grid item>
                <IconButton color="secondary" onClick={() => onSelect(1)}>
                  <Box className={clsx(classes.grade, { [classes.gradeActive]: select === 1 })}>1</Box>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="secondary" onClick={() => onSelect(2)}>
                  <Box className={clsx(classes.grade, { [classes.gradeActive]: select === 2 })}>2</Box>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="secondary" onClick={() => onSelect(3)}>
                  <Box className={clsx(classes.grade, { [classes.gradeActive]: select === 3 })}>3</Box>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="secondary" onClick={() => onSelect(4)}>
                  <Box className={clsx(classes.grade, { [classes.gradeActive]: select === 4 })}>4</Box>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="secondary" onClick={() => onSelect(5)}>
                  <Box className={clsx(classes.grade, { [classes.gradeActive]: select === 5 })}>5</Box>
                </IconButton>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Button variant="contained" color="primary" sx={btn} onClick={onSubmit}>
                  Оценить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  content: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    maxWidth: '100%!important',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundColor: palette.primary.dark,
    backgroundImage: `url(${img.bgInterview})`,
    padding: `${50 * size.scale}px ${60 * size.scale}px!important`,
    [down(2000)]: {
      padding: `${50}px ${60}px!important`,
    },
    [down(1000)]: {
      padding: `${50 / size.scale}px ${60 / size.scale}px!important`,
    },
  },
  title: {
    fontWeight: 800,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: palette.primary.main,
    fontSize: `${40 * size.scale}px!important`,
    [down(2000)]: {
      fontSize: `${40}px!important`,
    },
    [down(1000)]: {
      fontSize: `${40 / size.scale}px!important`,
    },
  },
  grade: {
    lineHeight: 1,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: 100 * size.scale,
    justifyContent: 'center',
    height: 100 * size.scale,
    fontSize: 40 * size.scale,
    color: palette.primary.main,
    border: `2px solid ${palette.primary.main}`,
    [down(2000)]: {
      width: 100,
      height: 100,
      fontSize: 40,
    },
    [down(1000)]: {
      width: 100 / size.scale,
      height: 100 / size.scale,
      fontSize: 40 / size.scale,
    },
  },
  gradeActive: {
    color: palette.primary.dark,
    backgroundColor: palette.primary.main,
  },
}))

const btn = {
  color: '#000000',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
}

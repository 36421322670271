import React from 'react'
import parsed from 'query-string'
import { encode } from 'js-base64'
import * as process from 'process'
import { useDisplayMode } from '../../hooks'
import { DISPLAYMODE } from '../../types'

interface IDisplayMode {
  browserElement: React.FC
  children: React.ReactNode
}

export const DisplayMode: React.FC<IDisplayMode> = ({ browserElement: ComponentBrowser, children }): JSX.Element => {
  const displayMode = useDisplayMode()
  const { REACT_APP_METRICS: METRICS = '' } = process.env
  const token = encode(METRICS) in parsed.parse(window.location.hash)

  if (displayMode === DISPLAYMODE.BROWSER && token) {
    return <>{children}</>
  } else if (displayMode === DISPLAYMODE.BROWSER) {
    return <ComponentBrowser />
  }

  return <>{children}</>
}

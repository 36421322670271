import { useEffect, useState } from 'react'
import { DISPLAYMODE } from '../types'

export function useDisplayMode() {
  const [detect, setDetect] = useState<DISPLAYMODE.BROWSER | DISPLAYMODE.STANDALONE>(DISPLAYMODE.BROWSER)

  useEffect(() => {
    window.matchMedia(`(display-mode: ${DISPLAYMODE.STANDALONE})`).addEventListener('change', (evt) => {
      if (evt.matches) {
        setDetect(DISPLAYMODE.STANDALONE)
      }
    })
    if (window.matchMedia(`(display-mode: ${DISPLAYMODE.STANDALONE})`).matches) {
      setDetect(DISPLAYMODE.STANDALONE)
    }
  }, [])

  return detect
}

import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import { Modal } from '../../../components'
import { Product, SubmitGradeFormProps } from '../../../types'
import { GradeForm } from './grade.form'
import { size } from '../../../configs'

const gradeModalName = 'gradeModal'

interface IGradeModal {
  service: Product
  show: boolean
  onClose: () => void
  onOpenLowGrade: () => void
  onOpenHighGrade: () => void
  onSubmitGradeForm: (data: SubmitGradeFormProps) => void
}

export const GradeModal: React.FC<IGradeModal> = ({
  service,
  show = false,
  onClose = () => {},
  onOpenLowGrade = () => {},
  onOpenHighGrade = () => {},
  onSubmitGradeForm = () => {},
}): JSX.Element => {
  const classes = useStyles()

  const title = `Оценка услуги<br >«${service.name}»`

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  return (
    <Modal open={show} maxWidth="md" title={title} name={gradeModalName} onClose={onClose}>
      <Grid container justifyContent="center" className={classes.content}>
        <Grid item width={md ? 1100 / size.scale : 'auto'}>
          <GradeForm
            className={classes}
            onCloseGradeModal={onClose}
            onOpenLowGrade={onOpenLowGrade}
            onOpenHighGrade={onOpenHighGrade}
            onSubmitGradeForm={onSubmitGradeForm}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, font, palette }: Theme) => ({
  content: {
    marginTop: 10 * size.scale,
    '& .MuiFilledInput-root': {
      minWidth: `${574 * size.scale}px!important`,
      maxWidth: `${574 * size.scale}px!important`,
      border: '1px solid #E5E5E5!important',
      '&:before': {
        display: 'none',
      },
      [down(2000)]: {
        minWidth: `${574}px!important`,
        maxWidth: `${574}px!important`,
      },
      [down(1000)]: {
        minWidth: `${574 / size.scale}px!important`,
        maxWidth: `${574 / size.scale}px!important`,
      },
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    '& .MuiFormHelperText-root': {
      color: '#FD3131',
    },
    '& .MuiButton-root': {
      fontSize: `${22 * size.scale}px!important`,
      [down(2000)]: {
        fontSize: `${22}px!important`,
      },
      [down(1000)]: {
        fontSize: `${22 / size.scale}px!important`,
      },
    },
    [down(2000)]: {
      marginTop: 10,
    },
    [down(1000)]: {
      marginTop: 10 / size.scale,
    },
  },
  grade: {
    width: 1035 * size.scale,
    marginBottom: 80 * size.scale,
    [down(2000)]: {
      width: 1035,
      marginBottom: 80,
    },
    [down(1000)]: {
      width: 1035 / size.scale,
      marginBottom: 80 / size.scale,
    },
  },
  itemGrade: {
    fontWeight: 700,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: 90 * size.scale,
    height: 90 * size.scale,
    justifyContent: 'center',
    fontSize: 40 * size.scale,
    fontFamily: font.secondary,
    border: `1px solid ${palette.primary.dark}`,
    [down(2000)]: {
      width: 90,
      height: 90,
      fontSize: 40,
    },
    [down(1000)]: {
      width: 90 / size.scale,
      height: 90 / size.scale,
      fontSize: 40 / size.scale,
    },
  },
  titleGrade: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 28 * size.scale,
    textTransform: 'uppercase',
    fontFamily: font.secondary,
    color: palette.primary.dark,
    marginBottom: 32 * size.scale,
    [down(2000)]: {
      fontSize: 28,
      marginBottom: 32,
    },
    [down(1000)]: {
      fontSize: 28 / size.scale,
      marginBottom: 32 / size.scale,
    },
  },
  selectItemGrade: {
    color: palette.primary.main,
    backgroundColor: palette.primary.dark,
  },
  btnGrade: {
    padding: '4px!important',
  },
}))

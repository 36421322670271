import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useScript } from '../../../hooks'

interface ISonlineWidget {
  placeid: number
  className: any
}

export const SonlineWidget: React.FC<ISonlineWidget> = ({ placeid, className }): JSX.Element => {
  const sonlineWidgetOptions = { placeid }

  const { appendScriptStatus } = useScript('https://sonline.su/js/widget3/widget3.js')

  useEffect(() => {
    if (appendScriptStatus) window.showSonlineWidget(sonlineWidgetOptions)
  }, [appendScriptStatus])

  return <Box id="widgetSonline" className={className.sonlineWidget} />
}

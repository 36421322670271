import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { Grid, Box, Theme, IconButton, Button, CircularProgress, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { formLocale, Layout, TrackEvent } from '../../components'
import { size } from '../../configs'
import { ArrowBackIcon, img } from '../../imgs'
import { detectNetwork } from '../../utils'
import { Final } from './final'
import { DiagnosticsFinish, DiagnosticsList } from '../../types'
import { diagnosticsList, diagnosticsStore } from '../../api'
import { Question } from './question'
import { errorFormToast } from '../../toast'

export const DiagnosticsPage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()

  const { serverError } = formLocale

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const [diagnostics, setDiagnostics] = useState<DiagnosticsList[]>([])
  const [diagnosticsFinish, setDiagnosticsFinish] = useState<DiagnosticsFinish[]>([])
  const [question, setQuestion] = useState<number>(0)
  const [variants, setVariants] = useState<any>([])

  const onBack = () => navigate({ pathname: '../', search: '?index=main' })

  const onPrev = () => setQuestion(question - 1)

  const onNext = () => {
    if (diagnostics.length > question) {
      setQuestion(question + 1)
      if (diagnostics.length === question + 1) {
        detectNetwork(() => {
          const interview_answer = []

          for (let i = 0; variants.length > i; i++) {
            interview_answer.push({ id: variants[i].id })
          }

          TrackEvent({
            eventCategory: 'Click',
            eventAction: 'result',
          })

          diagnosticsStore({ interview_answer })
            .then((res) => {
              if (res.status === 1) {
                setDiagnosticsFinish(res.data)
                TrackEvent({
                  eventCategory: 'Diagnostics',
                  eventAction: 'send_r_form',
                  eventLabel: 'success',
                })
              }
            })
            .catch((err) => {
              if (err.response.data.status === -1 || err.response.data.status === 0) {
                TrackEvent({
                  eventCategory: 'Diagnostics',
                  eventAction: 'send_r_form',
                  eventLabel: 'unsuccess',
                  eventContext: err.response.data.message,
                })
                errorFormToast(err.response.data.message)
              } else {
                errorFormToast(serverError)
              }
            })
        })
      }
    }
  }

  const onResetDiagnostics = () => {
    TrackEvent({
      eventCategory: 'Click',
      eventAction: 'again',
    })
    setQuestion(0)
    onDiagnosticsList()
  }

  const onSelectAnswer = (indexVariant: number, indexAnswer: number, answers: any) => {
    const variantsCopy = Object.assign([], variants)

    variantsCopy[indexVariant].index = indexAnswer
    variantsCopy[indexVariant].id = answers[indexAnswer].id

    setVariants(variantsCopy)
  }

  const onDiagnosticsList = useCallback(() => {
    diagnosticsList().then((res) => {
      if (res.status === 1) {
        const initVariants = []

        for (let i = 0; res.data.length > i; i++) initVariants.push({ index: null, id: null })

        setDiagnostics(res.data)
        setVariants(initVariants)
        TrackEvent({
          eventCategory: 'Diagnostics',
          eventAction: 'send_a_form',
        })
      }
    })
  }, [])

  useEffect(() => onDiagnosticsList(), [])

  return (
    <Layout>
      <Box position="relative" width="100%" height="100%">
        <Box position="absolute" top={`${md ? 40 / size.scale : 40}px`} left={`${md ? 60 / size.scale : 60}px`}>
          <IconButton color="secondary" onClick={onBack}>
            <ArrowBackIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
          </IconButton>
        </Box>

        <Grid container wrap="nowrap" height="100%">
          <Grid item width={!xl ? 480 * size.scale : md ? 480 / size.scale : 480}>
            <Box className={classes.bgImg} />
          </Grid>
          <Grid item width={!xl ? 1440 * size.scale : md ? 1440 / size.scale : 1440}>
            <Box className={classes.content}>
              {diagnostics.length > 0 && variants.length > 0 ? (
                <>
                  {diagnostics.length > question ? (
                    <>
                      <Box className={classes.status}>
                        <Box className={classes.number}>
                          Вопрос&nbsp;{question + 1}&nbsp;
                          <span style={{ color: '#A4A4A4' }}>из&nbsp;{diagnostics.length}</span>
                        </Box>
                        <Grid container wrap="nowrap" spacing={1}>
                          {diagnostics.map((_, i) => {
                            return (
                              <Grid key={`item-status-${i}`} item width={`${100 / diagnostics.length}%`}>
                                <Box
                                  width="100%"
                                  height={10 / size.scale}
                                  bgcolor={question >= i ? '#000000' : '#E7E7E7'}
                                />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Box>
                      <Box width="100%" height={!xl ? 820 * size.scale : md ? 850 / size.scale : 760}>
                        {diagnostics.map((k, i) => {
                          return (
                            <Fragment key={`question-${i}`}>
                              {question === i && (
                                <Question
                                  title={k.name}
                                  answers={k.answers}
                                  variant={variants[i].index}
                                  onSelect={(indexAnswer) => onSelectAnswer(i, indexAnswer, k.answers)}
                                />
                              )}
                            </Fragment>
                          )
                        })}
                      </Box>
                      <Grid container wrap="nowrap" justifyContent="space-evenly">
                        {question > 0 && (
                          <Grid item>
                            <Button variant="contained" color="primary" className={classes.btnPrimary} onClick={onPrev}>
                              Назад
                            </Button>
                          </Grid>
                        )}
                        <Grid item>
                          <Button
                            onClick={onNext}
                            color="secondary"
                            variant="contained"
                            className={classes.btnSecondary}
                            disabled={variants[question].id === null}
                          >
                            {diagnostics.length > question + 1 ? 'Следующий вопрос' : 'посмотреть результаты'}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      {diagnosticsFinish.length > 0 ? (
                        <Final diagnosticsFinish={diagnosticsFinish} onResetDiagnostics={onResetDiagnostics} />
                      ) : (
                        <Grid container justifyContent="center" alignItems="center" height="100%">
                          <Grid item>
                            <CircularProgress color="secondary" />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Grid container justifyContent="center" alignItems="center" height="100%">
                  <Grid item>
                    <CircularProgress color="secondary" />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, font, palette }: Theme) => ({
  content: {
    height: '100%',
    overflow: 'hidden',
    width: 1440 * size.scale,
    backgroundColor: palette.primary.main,
    '& .Mui-disabled': { borderColor: '#C9C9C9!important' },
    [down(2000)]: {
      width: 1440,
    },
    [down(1000)]: {
      width: 1440 / size.scale,
    },
  },
  bgImg: {
    backgroundSize: 'cover',
    width: 480 * size.scale,
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundImage: `url(${img.bgDiagnostics})`,
    [down(2000)]: {
      width: 480,
    },
    [down(1000)]: {
      width: 480 / size.scale,
    },
  },
  status: {
    margin: `${35 * size.scale}px ${40 * size.scale}px`,
    [down(2000)]: {
      margin: `${35}px ${40}px`,
    },
    [down(1000)]: {
      margin: `${50 / size.scale}px ${40 / size.scale}px`,
    },
  },
  number: {
    fontWeight: 600,
    fontSize: 26 / size.scale,
    fontFamily: font.secondary,
    color: palette.primary.dark,
    marginBottom: 20 / size.scale,
    [down(2000)]: {
      fontSize: 26,
      marginBottom: 20,
    },
    [down(1000)]: {
      fontSize: 26 / size.scale,
      marginBottom: 30 / size.scale,
    },
  },
  btnSecondary: {
    fontSize: `${22 * size.scale}px!important`,
    minWidth: `${420 * size.scale}px!important`,
    borderColor: `${palette.primary.dark}!important`,
    [down(2000)]: {
      fontSize: `${22}px!important`,
      minWidth: `${420}px!important`,
    },
    [down(1000)]: {
      fontSize: `${22 / size.scale}px!important`,
      minWidth: `${420 / size.scale}px!important`,
    },
  },
  btnPrimary: {
    fontSize: `${22 * size.scale}px!important`,
    color: `${palette.primary.dark}!important`,
    minWidth: `${420 * size.scale}px!important`,
    borderColor: `${palette.primary.dark}!important`,
    [down(2000)]: {
      fontSize: `${22}px!important`,
      minWidth: `${420}px!important`,
    },
    [down(1000)]: {
      fontSize: `${22 / size.scale}px!important`,
      minWidth: `${420 / size.scale}px!important`,
    },
  },
}))

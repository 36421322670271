import React, { FC, useState } from 'react'
import { Box, Button, DialogActions } from '@mui/material'
import * as ls from 'local-storage'
import { size } from '../../configs'
import { Modal } from '../modal'

export const HotReload: FC = (): JSX.Element => {
  const [reload, setReload] = useState<boolean>(false)

  const onChangeReload = () => {
    setReload((prev) => !prev)
  }

  const onOpen = () => {
    setReload((prev) => !prev)
  }

  return (
    <>
      <Modal open={reload} title="Обновление приложения" onClose={onOpen}>
        <Box textAlign="center" mb={{ xs: 5 / size.scale, md: 5, xl: 5 * size.scale }} lineHeight={1.4}>
          Проверить наличие
          <br />
          обновлений приложения?
        </Box>
        <DialogActions id="reload-actions">
          <Box mx="auto" maxWidth={{ xs: 574 / size.scale, md: 574, xl: 574 * size.scale }} width="100%">
            <Button
              fullWidth
              onClick={() => {
                ls.set('__check_update', new Date())
                window.location.reload()
              }}
              color="secondary"
              variant="contained"
              sx={{ fontSize: { xs: 22 / size.scale, md: 22, xl: 22 * size.scale } }}
            >
              Проверить
            </Button>
          </Box>
        </DialogActions>
      </Modal>
      <Box
        top={0}
        right={0}
        position="absolute"
        onClick={onChangeReload}
        width={{ xs: 250 / size.scale, md: 250, xl: 250 * size.scale }}
        height={{ xs: 250 / size.scale, md: 250, xl: 250 * size.scale }}
      />
    </>
  )
}

import React, { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Container, Grid, IconButton, Stack, Theme, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Layout, Modal } from '../../components'
import { size } from '../../configs'
import { ArrowBackIcon, img, LogoIcon } from '../../imgs'
import test from '../../imgs/test/test-gallery.png'
import { getGallery } from '../../api'
import { Gallery, Media } from '../../types'

interface IItem extends Media {
  className: any
  onClick: () => void
}

interface IViewing {
  type: 1 | 2
  open: boolean
  select: string
  className: any
  onClose: () => void
}

const { REACT_APP_API_URL: API_URL, DEBUG } = process.env

const Photo: FC<IItem> = ({ className, onClick, src }): JSX.Element => {
  const image = `${DEBUG ? test : API_URL}${src}`

  return <Box className={className.photo} sx={{ backgroundImage: `url(${image})` }} onClick={onClick} />
}

const Video: FC<IItem> = ({ className, onClick, src }): JSX.Element => {
  const video = `${DEBUG ? test : API_URL}${src}`

  return (
    <Box className={className.video} onClick={onClick}>
      <Grid container justifyContent="center" alignItems="center" zIndex={1} height="100%" position="absolute">
        <Grid item height="100%">
          <video height="100%">
            <source src={video} />
          </video>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" height="100%" position="absolute">
        <Grid item>
          <Box className={className.play} />
        </Grid>
      </Grid>
    </Box>
  )
}

const Viewing: FC<IViewing> = ({ type, className, open, select, onClose }): JSX.Element => {
  const source = `${DEBUG ? test : API_URL}/${select}`

  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down(1000))

  return (
    <Modal maxWidth="lg" open={open} onClose={onClose}>
      <Box my={2}>
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: 'hidden' }}>
          <Grid item height={{ xs: 970 / size.scale, md: 970, xl: 970 * size.scale }}>
            {type === 1 ? (
              <img src={source} alt="test" className={className.viewing} />
            ) : (
              <video height="100%" autoPlay controls controlsList="nodownload nofullscreen">
                <source src={source} />
              </video>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export const AlbumPage: FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { slug } = useParams()

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const [viewing, setViewing] = useState<number | undefined>(undefined)
  const [tab, setTab] = useState<1 | 2>(1)
  const [gallery, setGallery] = useState<Gallery | undefined>(undefined)
  const [video, setVideo] = useState<Media[]>([])
  const [photo, setPhoto] = useState<Media[]>([])

  const onBack = () => navigate(`../gallery`)

  const onOpenPhoto = (s?: number) => {
    if (s) setViewing(s)
    else setViewing(undefined)
  }

  const uploadGallery = useCallback(() => {
    getGallery().then((res) => {
      setGallery(res.data.filter((k: Gallery) => slug === k.slug)[0])
    })
  }, [])

  useEffect(() => {
    uploadGallery()
  }, [])

  useEffect(() => {
    if (gallery) {
      const photoMedia = gallery.media?.filter((k: Media) => k.type === 'image')
      const galleryMedia = gallery.media?.filter((k: Media) => k.type === 'video')

      if (photoMedia?.length === 0) setTab(2)
      setPhoto(photoMedia)
      setVideo(galleryMedia)
    }
  }, [gallery])

  return (
    <Layout touch>
      {gallery && viewing && (
        <Viewing
          type={tab}
          open={!!viewing}
          className={classes}
          onClose={() => onOpenPhoto()}
          select={gallery.media.filter((k) => k.id === viewing)[0].src}
        />
      )}

      <Box className={classes.content}>
        <Container className={classes.container}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box width={md ? 236 / size.scale : 236}>
                <IconButton color="secondary" onClick={onBack}>
                  <ArrowBackIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.title} fontSize={!xl ? 40 * size.scale : md ? 40 / size.scale : 40}>
                Название альбома или мероприятия
              </Box>
            </Grid>
            <Grid item>
              <Box mr={1}>
                <LogoIcon width={md ? 235 / size.scale : 235} height={md ? 93 / size.scale : 93} />
              </Box>
            </Grid>
          </Grid>

          <Container className={classes.album}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              mb={{ xs: 5 / size.scale, md: 5, xl: 5 * size.scale }}
            >
              <Grid item>
                <Box className={classes.all} width={{ xs: 140 / size.scale, md: 140, xl: 140 * size.scale }}>
                  {tab === 1 ? `${photo.length} фото` : `${video.length} видео`}
                </Box>
              </Grid>
              <Grid item>
                <Stack
                  fontSize={20}
                  direction="row"
                  fontWeight={700}
                  flexWrap="nowrap"
                  justifyContent="center"
                  spacing={{ xs: `${40 / size.scale}px`, md: `${40}px`, xl: `${40 * size.scale}px` }}
                >
                  {photo && photo.length > 0 && (
                    <Box
                      sx={{
                        cursor: 'pointer',
                        textAlign: 'right',
                        transition: '0.3s',
                        textTransform: 'uppercase',
                        opacity: tab === 1 ? 1 : 0.5,
                        fontSize: { xs: 38 / size.scale, md: 38, xl: 38 * size.scale },
                      }}
                      onClick={() => setTab(1)}
                      width={{ xs: 160 / size.scale, md: 160, xl: 160 * size.scale }}
                    >
                      Фото
                    </Box>
                  )}
                  {video && video.length > 0 && (
                    <Box
                      sx={{
                        cursor: 'pointer',
                        transition: '0.3s',
                        textTransform: 'uppercase',
                        opacity: tab === 2 ? 1 : 0.5,
                        fontSize: { xs: 38 / size.scale, md: 38, xl: 38 * size.scale },
                      }}
                      onClick={() => setTab(2)}
                      width={{ xs: 160 / size.scale, md: 160, xl: 160 * size.scale }}
                    >
                      Видео
                    </Box>
                  )}
                </Stack>
              </Grid>
              <Grid item>
                <Box width={{ xs: 140 / size.scale, md: 140, xl: 140 * size.scale }} />
              </Grid>
            </Grid>

            <Box hidden={tab !== 1} sx={{ transition: '0.3s all' }}>
              <Grid container>
                {photo.map((k: Media, i: number) => (
                  <Grid key={`photo-${i}`} item xs={3}>
                    <Photo className={classes} onClick={() => onOpenPhoto(k.id)} {...k} />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box hidden={tab !== 2} sx={{ transition: '0.3s all' }}>
              <Grid container>
                {video.map((k: Media, i: number) => (
                  <Grid key={`photo-${i}`} item xs={3}>
                    <Video className={classes} onClick={() => onOpenPhoto(k.id)} {...k} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Container>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  content: {
    width: '100%',
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundImage: `url(${img.bgReview})`,
  },
  container: {
    height: '100%',
    overflow: 'hidden',
    maxWidth: '100%!important',
    padding: `${40 * size.scale}px ${60 * size.scale}px!important`,
    [down(2000)]: { padding: `${40}px ${60}px!important` },
    [down(1000)]: { padding: `${50 / size.scale}px ${60 / size.scale}px!important` },
  },
  title: {
    fontWeight: 800,
    textTransform: 'uppercase',
    color: palette.primary.main,
  },
  all: {
    color: 'rgba(255, 255, 255, 0.6)',
    whiteSpace: 'nowrap',
    fontSize: 25 * size.scale,
    [down(2000)]: { fontSize: 25 },
    [down(1000)]: { fontSize: 25 / size.scale },
  },
  album: {
    height: '100%',
    padding: '0 8px!important',
    maxWidth: '100%!important',
    marginTop: `${40 * size.scale}px!important`,
    [down(2000)]: { marginTop: `${40}px!important` },
    [down(1000)]: { marginTop: `${40 / size.scale}px!important` },
  },
  photo: {
    width: '100%',
    height: 430 * size.scale,
    backgroundSize: '100% auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [down(2000)]: { height: 430 },
    [down(1000)]: { height: 430 / size.scale },
  },
  video: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    height: 430 * size.scale,
    '&:after': {
      left: 0,
      zIndex: 2,
      bottom: 0,
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    [down(2000)]: { height: 430 },
    [down(1000)]: { height: 430 / size.scale },
  },
  viewing: {
    height: '100%',
  },
  play: {
    zIndex: 3,
    position: 'relative',
    transform: `translateX(${30 * size.scale}px)`,
    borderLeft: `${80 * size.scale}px solid rgba(255, 255, 255, 0.8)`,
    border: `${50 * size.scale}px solid transparent`,
    [down(2000)]: {
      transform: `translateX(${30}px)`,
      borderLeft: `${80}px solid rgba(255, 255, 255, 0.8)`,
      border: `${50}px solid transparent`,
    },
    [down(1000)]: {
      transform: `translateX(${30 / size.scale}px)`,
      borderLeft: `${80 / size.scale}px solid rgba(255, 255, 255, 0.8)`,
      border: `${50 / size.scale}px solid transparent`,
    },
  },
}))

/* eslint-disable max-len */
import React from 'react'
import { size } from '../../configs'

interface IDiscountIcon {
  width?: number
  height?: number
}

export const DiscountIcon: React.FC<IDiscountIcon> = ({ width = 70, height = 70 }): JSX.Element => (
  <svg
    width={`${width / size.scale}`}
    height={`${height / size.scale}`}
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.5439 43.0747L38.5923 61.031C38.1273 61.4967 37.575 61.8661 36.9671 62.1182C36.3592 62.3703 35.7076 62.5 35.0496 62.5C34.3915 62.5 33.7399 62.3703 33.132 62.1182C32.5241 61.8661 31.9719 61.4967 31.5068 61.031L10 39.5436V14.5H35.0371L56.5439 36.0125C57.4765 36.9509 58 38.2204 58 39.5436C58 40.8668 57.4765 42.1363 56.5439 43.0747V43.0747Z"
      stroke="#E06AB1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 22.5C15 21.1193 16.111 20 17.4814 20H17.5186C18.889 20 20 21.1193 20 22.5C20 23.8807 18.889 25 17.5186 25H17.4814C16.111 25 15 23.8807 15 22.5Z"
      fill="#E06AB1"
    />
    <path d="M17.5 22.5C9.5 15 18.5 13.5 13 6.5" stroke="#E06AB1" strokeWidth="2" />
    <path
      d="M30.2953 42.9732C29.3378 43.903 28.2405 44.3405 27.0032 44.2856C25.7664 44.2062 24.6166 43.6193 23.5539 42.5251C22.4913 41.4308 21.9444 40.2706 21.9134 39.0443C21.8827 37.7936 22.346 36.7033 23.3035 35.7735C24.261 34.8436 25.3582 34.4184 26.595 34.4978C27.8199 34.5648 28.9636 35.1454 30.0262 36.2397C31.0889 37.334 31.6418 38.5004 31.6849 39.7391C31.716 40.9653 31.2528 42.0434 30.2953 42.9732ZM31.2264 28.3694L33.446 26.2139L37.2097 47.9286L34.9901 50.0841L31.2264 28.3694ZM28.81 41.4437C29.2328 41.0331 29.3932 40.5391 29.2914 39.9614C29.202 39.3718 28.8252 38.735 28.161 38.051C27.4969 37.3671 26.8714 36.9718 26.2846 36.8652C25.7102 36.7464 25.2116 36.8924 24.7888 37.3029C24.3785 37.7014 24.224 38.2017 24.3255 38.8039C24.415 39.3935 24.7857 40.0241 25.4378 40.6956C26.0899 41.3671 26.7154 41.7624 27.3143 41.8815C27.9011 41.9881 28.3997 41.8422 28.81 41.4437ZM45.1325 40.5245C44.5108 41.1283 43.8123 41.5287 43.0372 41.7257C42.2624 41.8982 41.4726 41.8438 40.6677 41.5623C39.8628 41.2808 39.1102 40.7795 38.4098 40.0583C37.7094 39.3371 37.2303 38.5701 36.9726 37.7573C36.7148 36.9445 36.6895 36.1596 36.8968 35.4026C37.1043 34.6212 37.519 33.9286 38.1407 33.3248C39.0982 32.395 40.1954 31.9698 41.4323 32.0492C42.6695 32.1041 43.8194 32.6787 44.8821 33.7729C45.9448 34.8672 46.4914 36.0397 46.5221 37.2904C46.5532 38.5167 46.09 39.5947 45.1325 40.5245ZM43.6472 38.995C44.07 38.5845 44.2305 38.0904 44.1286 37.5128C44.0271 36.9106 43.6503 36.2738 42.9982 35.6024C42.3461 34.9309 41.7267 34.5418 41.1399 34.4352C40.5534 34.304 40.0488 34.4437 39.626 34.8543C39.2157 35.2528 39.0552 35.7468 39.1446 36.3365C39.2341 36.9262 39.6109 37.563 40.275 38.2469C40.9392 38.9308 41.5647 39.3261 42.1515 39.4328C42.7383 39.5395 43.2369 39.3935 43.6472 38.995Z"
      fill="#E06AB1"
    />
  </svg>
)

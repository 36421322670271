import React from 'react'
import { Box, Grid, Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { img, LogoIcon } from '../../imgs'
import { size } from '../../configs'

export const NotFoundPage: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()

  const onMain = () => navigate({ pathname: '../', search: '?index=main' })

  return (
    <>
      <Box className={classes.page}>
        <Grid container alignItems="center" justifyContent="center" height="100%">
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <LogoIcon />
              </Grid>
            </Grid>
            <Box p={6} textAlign="center">
              <Box className={classes.text}>Страница не найдена</Box>
            </Box>
            <Box display="flex" justifyContent="center">
              <Button fullWidth variant="contained" color="primary" className={classes.button} onClick={onMain}>
                Вернуться на главную
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  page: {
    height: '100vh',
    padding: '0 15px',
    backgroundSize: 'cover',
    color: palette.primary.main,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundImage: `url(${img.bgReview})`,
  },
  text: {
    fontSize: 50 / size.scale,
    fontWeight: 600,
  },
  button: {
    maxWidth: 300,
    fontSize: 18,
  },
}))

import React, { useEffect, useState } from 'react'
import { GradeModal } from './grade.modal'
import { LowGrade } from './low-grade'
import { HighGrade } from './high-grade'
import { Product, ReviewStore, SubmitGradeFormProps } from '../../../types'
import { WaitingListModal } from '../../register/waiting-list'

interface IGradeRoot {
  idService: number
  service: Product
  showGradeModal: boolean
  onCloseGrade: () => void
}

export const GradeRoot: React.FC<IGradeRoot> = ({
  idService,
  service,
  showGradeModal = false,
  onCloseGrade = () => {},
}): JSX.Element => {
  const [reviewStore, setReviewStore] = useState<ReviewStore>({
    product_id: service.id,
    specialist: '',
    rating: 0,
    name: '',
    email: '',
    phone: '',
    note: '',
    rules: false,
    sms_subscribe: false,
    email_subscribe: false,
    review: [],
  })
  const [selectLowGradeModal, setSelectLowGradeModal] = useState<boolean>(false)
  const [recommendationHighGradeModal, setRecommendationHighGradeModal] = useState<boolean>(false)
  const [waitingListModal, setWaitingListModal] = useState<boolean>(false)
  const [locationQRCode, setLocationQRCode] = useState<boolean>(false)

  const onSubmitGradeForm = (data: SubmitGradeFormProps) => {
    setReviewStore({ ...reviewStore, ...data })
    onCloseGrade()
  }

  const onOpenSelectLowGrade = () => setSelectLowGradeModal(true)

  const onCloseSelectLowGrade = () => setSelectLowGradeModal(false)

  const onOpenRecommendationHighGrade = () => setRecommendationHighGradeModal(true)

  const onCloseRecommendationHighGrade = () => setRecommendationHighGradeModal(false)

  const onOpenWaitingListModal = () => setWaitingListModal(true)

  const onCloseWaitingListModal = () => setWaitingListModal(false)

  const onOpenLocationQRCodeModal = () => setLocationQRCode(true)

  const onCloseLocationQRCodeModal = () => setLocationQRCode(false)

  return (
    <>
      <GradeModal
        service={service}
        show={showGradeModal}
        onClose={onCloseGrade}
        onSubmitGradeForm={onSubmitGradeForm}
        onOpenLowGrade={onOpenSelectLowGrade}
        // onOpenHighGrade={onOpenRecommendationHighGrade}
        onOpenHighGrade={onOpenLocationQRCodeModal}
      />
      <LowGrade
        idService={idService}
        reviewStore={reviewStore}
        showSelectLowGrade={selectLowGradeModal}
        onSelectLowGrade={onCloseSelectLowGrade}
        onOpenWaitingListModal={onOpenWaitingListModal}
      />
      <HighGrade
        idService={idService}
        reviewStore={reviewStore}
        locationQRCode={locationQRCode}
        onOpenWaitingListModal={onOpenWaitingListModal}
        onOpenLocationQRCodeModal={onOpenLocationQRCodeModal}
        onCloseLocationQRCodeModal={onCloseLocationQRCodeModal}
        showRecommendationHighGrade={recommendationHighGradeModal}
        onCloseRecommendationHighGrade={onCloseRecommendationHighGrade}
      />
      <WaitingListModal service={service} show={waitingListModal} onClose={onCloseWaitingListModal} />
    </>
  )
}

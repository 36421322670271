/* eslint-disable max-len */
import React from 'react'
import { size } from '../../configs'
import { img } from '../index'

interface ILogoIcon {
  width?: number
  height?: number
}

export const LogoIcon: React.FC<ILogoIcon> = ({ width = 343, height = 135 }): JSX.Element => (
  <img src={img.logo} alt="" width={`${width}px`} height={`${height}px`} />
)
